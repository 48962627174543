import { GET_WEL_INFO_REQUEST, GET_WEL_INFO_SUCCESS, GET_WEL_INFO_FAIL } from '../actions/welInfo'

const initWelInfo = {
  loading: false,
  welInfo: {
    total_supply: 44917089772,
    total_supply_change: 0,
    total_circulating: 44917089772,
    total_circulating_change: 0,
    tvl: 37162331862,
    tvl_change: 0,
  },
  message: null,
}

export function welInfoReducer(state = initWelInfo, action) {
  switch (action.type) {
    case GET_WEL_INFO_REQUEST:
      return { ...state, loading: true }
    case GET_WEL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        welInfo: action.payload,
      }
    case GET_WEL_INFO_FAIL:
      return { ...state, loading: false }
    default:
      return state
  }
}
