export var ValidateAddress = new RegExp('^[W][a-zA-Z0-9]{33}$')
import { API_ADDR } from '../config/config'

export function GetPrivateKeyByAddress(list_private_key, address) {
  for (let index = 0; index < list_private_key.length; index++) {
    const element = list_private_key[index]
    if (element.address == address) {
      return element.prikey
    }
  }
  return ''
}

export function GetAddressByPrivateKey(list_private_key, privatekey) {
  for (let index = 0; index < list_private_key.length; index++) {
    const element = list_private_key[index]
    if (element.prikey == privatekey) {
      return element.address
    }
  }
  return ''
}
