import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'

const UL = styled.ul`
  list-style: none;
  padding-left: 5px;
  a {
    color: ${({ theme }) => theme.colors.gray_8};
    line-height: 2.5;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
`

const FooterDivider = styled(Divider)`
  margin: 10px 0 25px 0;
  border-color: rgba(255, 255, 255, 0.1);
  width: 50%;
  min-width: 50%;
`

export default function FooterLink({ title, links }) {
  return (
    <div>
      <Title>{title}</Title>
      <FooterDivider />
      <UL>
        {links.map((link, i) => {
          return (
            <li key={i}>
              <a href={link.href} target="_blank">
                {link.anchor}
              </a>
            </li>
          )
        })}
      </UL>
    </div>
  )
}
