import { combineReducers } from 'redux'
import { blockReducer, blockTransactionReducer } from './block'
import {
  homeBlockReducer,
  homeTransReducer,
  homeSystemStateReducer,
  searchReducer,
  autoCompleteSearchReducer,
  homeWelPriceStateReducer,
  homeWelHistoricyReducer,
} from './home'
import {
  decimalContractReducer,
  decodeReducer,
  eventLogsReducer,
  transactionReducer,
} from './transaction'
import { transactionsReducer } from './transactions'
import { chartsReducer } from './charts'
import { accountReducer } from './account'
import { contractReducer } from './contract'
import { witnessesReducer } from './witnesses'
import { tokensReducer } from './tokens'
import { hotTokensReducer } from './tokens'
import { tokenReducer } from './token'
import { pageTokensReducer } from './tokens'
import { blocksReducer } from './blocks'
import { pageBlocksReducer } from './blocks'
import { nodesReducer } from './nodes'
import { loginReducer } from './login'
import { assetManagementReducer } from './assetManagement'
import { issueTokenTRC10Reducer } from './issueTokenTRC10'
import { transferAssetReducer } from './transferasset'
import { freezeBalanceReducer } from './freezeBalance'
import { signUpReducer, activateMailReducer } from './signup'
import { deployContractReducer } from './deployContract'
import { sendTokenReducer, newPasswordReducer } from './resetPassword'
import { prikeyManagementReducer } from './prikeyManagement'
import { contractsReducer } from './contracts'

import { addressReducer, addressTransactionReducer, addressAdditionInfoReducer } from './address'
import { addressTransactionsReducer, pageAddressTransactionReducer } from './addressTransaction'

import { addressesReducer, pageAddressesReducer } from './addresses'
import { contractTransactionsReducer, pageContractTransactionReducer } from './contractTransaction'
import { addressTokensReducer, pageAddressTokenReducer } from './addressToken'
import { addressTransfersReducer, pageAddressTransferReducer } from './addressTransfer'
import { transferWRC20Reducer } from './transferWRC20'
import { defaultPrivateKeyReducer } from './defaultPrivateKey'
import { transferLoadRC10RC20Reducer } from './transferLoadRC10RC20'
import { checkUrlPrivReducer } from './checkUrlPriv'
import { tokenRecordReducer } from './tokenRecord'
import { pageTokenHoldersReducer, tokenHoldersReducer } from './tokenHolder'
import { topTokenHoldersReducer } from './topTokenHolders'
import { findTokenHoldersReducer } from './findTokenHolders'
import { resourcesReducer } from './resources'
import { unfreezeBalanceReducer } from './unfreezeBalance'
import { becomeWitnessReducer } from './becomeWitness'
import { votesReducer } from './votes'
import { rewardReducer } from './reward'
import { allTokensReducer, pageAllTokensReducer } from './allTokens'
import { marketInfoReducer } from './marketInfo'
import { welInfoReducer } from './welInfo'
import { searchListReducer } from './searchList'

export default combineReducers({
  search: searchReducer,
  autoCompleteSearch: autoCompleteSearchReducer,
  system: homeSystemStateReducer,
  homeBlocks: homeBlockReducer,
  homeTrans: homeTransReducer,
  welPrice: homeWelPriceStateReducer,
  welHistorical: homeWelHistoricyReducer,
  block: blockReducer,
  blockTransaction: blockTransactionReducer,
  transaction: transactionReducer,
  charts: chartsReducer,
  transactions: transactionsReducer,
  account: accountReducer,
  contract: contractReducer,
  witnesses: witnessesReducer,
  tokens: tokensReducer,
  hotTokensReducer: hotTokensReducer,
  nodes: nodesReducer,
  allTokens: allTokensReducer,
  pageAllTokens: pageAllTokensReducer,
  pageTokens: pageTokensReducer,
  blocks: blocksReducer,
  pageBlocks: pageBlocksReducer,
  token: tokenReducer,
  login: loginReducer,
  assetManagement: assetManagementReducer,
  issueTokenTRC10: issueTokenTRC10Reducer,
  deployContract: deployContractReducer,
  transferAsset: transferAssetReducer,
  signUpReducer: signUpReducer,
  freezeBalance: freezeBalanceReducer,
  sendToken: sendTokenReducer,
  newPassword: newPasswordReducer,
  prikeyManagement: prikeyManagementReducer,
  activateMail: activateMailReducer,

  contracts: contractsReducer,

  pageContractTransactions: pageContractTransactionReducer,
  contractTransactions: contractTransactionsReducer,

  address: addressReducer,
  addressTransaction: addressTransactionReducer,
  addressAdditionInfo: addressAdditionInfoReducer,

  pageAddressTransactions: pageAddressTransactionReducer,
  addressTransactions: addressTransactionsReducer,

  pageAddressTransfers: pageAddressTransferReducer,
  addressTransfers: addressTransfersReducer,

  pageAddressTokens: pageAddressTokenReducer,
  addressTokens: addressTokensReducer,

  addresses: addressesReducer,
  pageAddresses: pageAddressesReducer,

  transferWRC20: transferWRC20Reducer,

  defaultPrivateKey: defaultPrivateKeyReducer,

  transferLoadRC10RC20: transferLoadRC10RC20Reducer,

  checkUrlPriv: checkUrlPrivReducer,
  tokenRecord: tokenRecordReducer,

  tokenHolders: tokenHoldersReducer,
  pageTokenHolders: pageTokenHoldersReducer,
  topTokenHolders: topTokenHoldersReducer,
  findTokenHolders: findTokenHoldersReducer,

  resources: resourcesReducer,
  unfreezeBalance: unfreezeBalanceReducer,
  becomeWitness: becomeWitnessReducer,
  votes: votesReducer,
  reward: rewardReducer,

  marketInfo: marketInfoReducer,
  welInfo: welInfoReducer,

  searchList: searchListReducer,
  decode: decodeReducer,
  eventLogs: eventLogsReducer,
  decimalContract: decimalContractReducer,
})
