import { API_ADDR } from '../config/config'

export async function TransferAsset(privatekey, ownerAddress, toAddress, amount) {
  return await fetch(`${API_ADDR}/transferwel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      owner_address: ownerAddress,
      to_address: toAddress,
      private_key: privatekey,
      amount: amount,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status === 'success') {
        return {
          statusTransfer: res.data.result,
          transactionID: res.data.txid,
          messageTransfer: res.message,
        }
      } else {
        return {
          statusTransfer: false,
          transactionID: '',
          messageTransfer: res.message,
        }
      }
    })
    .catch((err) => {})
}

export async function TransferWRC10(privatekey, ownerAddress, toAddress, amount, assetName) {
  return await fetch(`${API_ADDR}/transferrc10`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      private_key: privatekey,
      owner_address: ownerAddress,
      to_address: toAddress,
      asset_name: assetName,
      amount: amount,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status === 'success') {
        return {
          statusTransfer: res.data.result,
          transactionID: res.data.txid,
          messageTransfer: res.message,
        }
      } else {
        return {
          statusTransfer: false,
          transactionID: '',
          messageTransfer: res.message,
        }
      }
    })
    .catch((err) => {})
}

export async function TransferWRC20(privatekey, contractAddress, ownerAddress, toAddress, amount) {
  return await fetch(`${API_ADDR}/transferrc20`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      contract_address: contractAddress,
      to_address: toAddress,
      owner_address: ownerAddress,
      amount: amount,
      private_key: privatekey,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status === 'success') {
        return {
          statusTransfer: res.data.result,
          transactionID: res.data.txid,
          messageTransfer: res.message,
        }
      } else {
        return {
          statusTransfer: false,
          transactionID: '',
          messageTransfer: res.message,
        }
      }
    })
    .catch((err) => {})
}
