import {
  BECOME_WITNESS_REQUEST,
  BECOME_WITNESS_SUCCESS,
  BECOME_WITNESS_FAIL,
} from '../actions/becomeWitness'

const initVote = {
  loading: false,
  txid: '',
  message: null,
}

export function becomeWitnessReducer(state = initVote, action) {
  switch (action.type) {
    case BECOME_WITNESS_REQUEST:
      return { ...state, loading: true, txid: '', message: null }
    case BECOME_WITNESS_SUCCESS:
      return { ...state, loading: false, txid: action.payload.txid }
    case BECOME_WITNESS_FAIL:
      return { ...state, loading: false, message: action.payload }
    default:
      return state
  }
}
