import fetch from 'cross-fetch'
import { API_ADDR, WS_ADDR } from '../config/config'

export const GET_WEL_INFO_REQUEST = 'GET_WEL_INFO_REQUEST'
export const GET_WEL_INFO_SUCCESS = 'GET_WEL_INFO_SUCCESS'
export const GET_WEL_INFO_FAIL = 'GET_WEL_INFO_FAIL'

export function getWelInfoRequest() {
  return {
    type: GET_WEL_INFO_REQUEST,
  }
}
export function getWelInfoSuccess(payload) {
  return {
    type: GET_WEL_INFO_SUCCESS,
    payload,
  }
}
export function getWelInfoFail() {
  return {
    type: GET_WEL_INFO_FAIL,
  }
}

export function getWelInfo() {
  return (dispatch) => {
    dispatch(getWelInfoRequest())
    fetch(`${API_ADDR}/wel-info`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        res.data && res.data.total_supply !== 0
          ? dispatch(getWelInfoSuccess(res.data))
          : dispatch(getWelInfoFail())
      })
      .then(() => {
        const ws = new WebSocket(`${WS_ADDR}/ws/wel-info`)
        ws.onerror = function () {}
        ws.onclose = function () {}
        ws.onmessage = (e) => {
          const data = JSON.parse(e.data)
          data && data.total_supply !== 0
            ? dispatch(getWelInfoSuccess(data))
            : dispatch(getWelInfoFail())
        }
      })
      .catch(() => dispatch(getWelInfoFail()))
  }
}
