import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const ALL_TOKENS_INIT = 'ALL_TOKENS_INIT'
export const ALL_TOKENS_UPDATE = 'ALL_TOKENS_UPDATE'

export function initAllTokens() {
  return {
    type: ALL_TOKENS_INIT,
  }
}
export function updateAllTokens(tokens) {
  return {
    type: ALL_TOKENS_UPDATE,
    payload: tokens,
  }
}

export function loadAllTokens(page, limit) {
  return (dispatch) => {
    dispatch(initAllTokens())
    fetch(`${API_ADDR}/tokenrecords?page=${page}&limit=${limit}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageAllTokensTotal(res.data.total))
        dispatch(updateAllTokens(res.data.result))
      })
      .catch(() => {})
  }
}

export const PAGE_ALL_TOKENS_INIT = 'PAGE_ALL_TOKENS_INIT'
export const PAGE_ALL_TOKENS_UPDATE = 'PAGE_ALL_TOKENS_UPDATE'
export const PAGE_ALL_TOKENS_TOTAL_UPDATE = 'PAGE_ALL_TOKENS_TOTAL_UPDATE'
export const PAGE_ALL_TOKENS_LIMIT_UPDATE = 'PAGE_ALL_TOKENS_LIMIT_UPDATE'

export function initPageAllTokens() {
  return {
    type: PAGE_ALL_TOKENS_INIT,
  }
}
export function updatePageAllTokens(page) {
  return {
    type: PAGE_ALL_TOKENS_UPDATE,
    payload: page,
  }
}

export function updatePageAllTokensTotal(total) {
  return {
    type: PAGE_ALL_TOKENS_TOTAL_UPDATE,
    payload: total,
  }
}

export function updatePageAllTokensLimit(limit) {
  return {
    type: PAGE_ALL_TOKENS_LIMIT_UPDATE,
    payload: limit,
  }
}
