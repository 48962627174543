import React, { Component, Suspense } from 'react'

import { Button, Col, Form, Input, InputNumber, Modal, Result, Row, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Inspect from 'inspx'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import TruncateMiddle from 'react-truncate-string'

import routes from './v2/routes'

// Components
import FooterComponent from './v2/components/footer'
import MainNavigation from './v2/components/mainNavigation'
import SearchBar from './v2/components/search/SearchBar'
import Body from './v2/components/body'
import ScrollToTop from './v2/components/scroll/scrollToTop'
import IDShareLoginCallbackPage from './v2/components/idshareLoginCallback'

// Utilities
import Account from './api/account'
import './api/oidc'
import { addressToHex, hexToAddress } from './utils/utils'

// Redux
import { loadFromStorage, logout } from './actions/login'
import { defaultURLPrivateKey } from './actions/checkUrlPriv'
import { initTokenRecord } from './actions/tokenRecord'

const AppWrapper = styled.div`
  flex: 1 0 auto;
  background-color: #262261;
  background-color: #262261;
  background-image: radial-gradient(
      circle,
      rgba(0, 255, 255, 0.15) 0%,
      rgba(0, 255, 255, 0) 75%,
      rgba(0, 212, 255, 0) 100%
    ),
    radial-gradient(
      circle,
      rgba(0, 255, 255, 0.15) 0%,
      rgba(0, 255, 255, 0) 75%,
      rgba(0, 212, 255, 0) 100%
    );
  background-position: -500px 600px, calc(100% + 500px) 1000px;
  background-size: 1000px 1000px;
  background-repeat: no-repeat;
  z-index: 1;
  @media (max-width: 768px) {
    z-index: auto;
  }

  :before {
    display: block;
    position: absolute;
    top: 420px;
    content: '';
    background-image: linear-gradient(to bottom, rgba(38, 34, 97, 0), #262261);
    width: 100%;
    height: 86px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  :after {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: ${({ bgHeight }) => bgHeight || 500}px;
    content: '';
    background-image: url(/bg.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 30%;
    z-index: -1;
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const ContentRowWrapper = styled(Row)`
  @media (max-width: 768px) {
    padding-top: 70px;
  }
`

const DecodeResultDiv = styled.div`
  word-break: break-word;
  margin-top: 10px;
  font-style: ${(props) => (props.error ? 'normal' : 'italic')};
  color: ${(props) => (props.error ? '#e50915' : 'rgb(0, 189, 12)')};
`

const DataModal = styled(Modal)`
  width: 100%;
  max-width: 960px;
  @media screen and (max-width: 475px) {
    width: 100%;
    max-width: 100%k;
  }

  .ant-modal-content {
    padding: 32px 50px;
    @media screen and (max-width: 768px) {
      padding: 20px 0px;
    }
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 24px;
    padding: 0px 24px;
    margin-top: 50px;

    button {
      width: 50%;
      padding: 16px 0px;
      height: auto;
      font-weight: bold;
      margin: 0px !important;

      &:not(.ant-btn-primary) {
        color: #808191;
        border-width: 2px;
        padding: 14px 0px;
      }
    }
  }
`

const DataCard = styled.div`
  background: #262261;
  border-radius: 24px;
  padding: 24px 80px;
  box-shadow: 0px 0px 80px -40px rgba(0, 0, 0, 0.3);
  label {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 30px;
  }
`

const DataCardTitle = styled.h4`
  font-size: 18px;
  color: #ffffff;
  line-height: 1.333;
`
const DataCardDesc = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #808191;
  margin: 16px auto 18px;
`
const DataCardValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(228, 228, 228, 0.1);
  padding: 16px 25px;
  border-radius: 12px;

  p {
    color: ${({ theme }) => theme.colors_v2.primary_yellow};
    margin: 0px;
  }
`

const TruncateStyle = styled(TruncateMiddle)`
  flex: 1;
  color: ${({ theme }) => theme.colors_v2.primary_yellow};
  margin: 0px;
`

const DataCardInput = styled(Input)`
  color: ${({ theme }) => theme.colors_v2.primary_yellow};
  background: rgba(228, 228, 228, 0.03);
  padding: 16px 25px;
  border-radius: 12px;
  margin-top: 26px;
  border: none;
`

function RouteWithBody({ ...props }) {
  return (
    <Body>
      <Route {...props} />
    </Body>
  )
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
      isModalDecodeVisible: false,
      newAddr: '',
      newPrivKey: '',
      password: '',
      decodeResult: { value: '', error: false },
    }
  }
  formRef = React.createRef()

  logOut = () => {
    this.props.logout(this.props.login.id)
    window.location.replace('/login')
  }
  generateAccount = () => {
    const newAcc = Account.createAccount()
    this.setState({
      isModalVisible: true,
      newAddr: newAcc.address,
      newPrivKey: newAcc.privateKey,
      password: newAcc.password,
    })
  }
  onOk = () => {
    this.setState({
      isModalVisible: false,
      isModalDecodeVisible: false,
      newAddr: '',
      newPrivKey: '',
      password: '',
      decodeResult: { value: '', error: false },
    })
  }
  handleDecodeAddressMenuClick = () => {
    this.setState({
      isModalDecodeVisible: true,
    })
  }
  handleDecodeAddress = () => {
    const address = this.formRef.current.getFieldValue('address')
    if (address && address.startsWith('41')) {
      const res = hexToAddress(address)
      if (res) {
        this.setState({ decodeResult: { value: res, error: false } })
      } else {
        this.setState({
          decodeResult: { value: 'Invalid address! Please try again!', error: true },
        })
      }
    } else {
      const res = addressToHex(address)
      if (res) {
        var decord_addr = res
        if (decord_addr.length > 2) {
          decord_addr = '41' + decord_addr.slice(2, decord_addr.length)
        }
        this.setState({ decodeResult: { value: decord_addr, error: false } })
      } else {
        this.setState({
          decodeResult: { value: 'Invalid address! Please try again!', error: true },
        })
      }
    }
  }

  renderRoute(route, idx) {
    const Component = route.component
    const routeProps = {
      path: route.path,
      render(props) {
        return <Component {...props}></Component>
      },
      key: `${route.path}-${idx}`,
    }
    if (route.exact) {
      routeProps.exact = true
    }
    return <RouteWithBody {...routeProps} />
  }
  componentDidMount() {
    let { login } = this.props
    if (login.token === '') {
      this.props.loadFromStorage()
    }
    this.props.checkUrlPrivFunc()
  }
  render() {
    var { login } = this.props
    const { decodeResult } = this.state
    return (
      <Inspect className="xxx" disabled={process.env.NODE_ENV === 'production'}>
        <Router>
          <ScrollToTop />
          <DataModal
            width={'90%'}
            title="New address"
            centered
            visible={this.state.isModalVisible}
            onOk={this.onOk}
            onCancel={this.onOk}
          >
            <DataCard>
              <DataCardTitle>Your address</DataCardTitle>
              <DataCardDesc>
                Failure to use the Memo may result in the loss of your deposit.
              </DataCardDesc>
              <DataCardValue>
                <Tooltip placement="top" title={this.state.newAddr}>
                  <TruncateStyle text={this.state.newAddr} />
                </Tooltip>
                <CopyToClipboard
                  text={this.state.newAddr}
                  onCopy={() => {
                    this.setState({ copied: true })
                    setTimeout(
                      function () {
                        this.setState({ copied: false })
                      }.bind(this),
                      1000
                    )
                  }}
                >
                  {this.state.copied ? (
                    <span style={{ marginLeft: '10px' }}>
                      <CheckCircleOutlined /> Copied
                    </span>
                  ) : (
                    <CopyOutlined style={{ marginLeft: '10px' }} />
                  )}
                </CopyToClipboard>
              </DataCardValue>
            </DataCard>

            <DataCard style={{ marginTop: '50px' }}>
              <DataCardTitle>Your private key</DataCardTitle>
              <DataCardDesc>
                Failure to use the Memo may result in the loss of your deposit.
              </DataCardDesc>
              <DataCardValue>
                <Tooltip placement="top" title={this.state.newPrivKey}>
                  <TruncateStyle text={this.state.newPrivKey} />
                </Tooltip>
                <CopyToClipboard
                  text={this.state.newPrivKey}
                  onCopy={() => {
                    this.setState({ copiedPrivateKey: true })
                    setTimeout(
                      function () {
                        this.setState({ copiedPrivateKey: false })
                      }.bind(this),
                      1000
                    )
                  }}
                >
                  {this.state.copiedPrivateKey ? (
                    <span style={{ marginLeft: '10px' }}>
                      <CheckCircleOutlined /> Copied
                    </span>
                  ) : (
                    <CopyOutlined style={{ marginLeft: '10px' }} />
                  )}
                </CopyToClipboard>
              </DataCardValue>
            </DataCard>
          </DataModal>
          <DataModal
            title="Decode Address"
            width={'90%'}
            centered
            visible={this.state.isModalDecodeVisible}
            onCancel={this.onOk}
            // footer={null}
            onOk={this.handleDecodeAddress}
            okText="Decode"
            destroyOnClose
          >
            <Form layout="vertical" name="decodeAddress" size="medium" ref={this.formRef}>
              <DataCard>
                <Form.Item
                  label="Input addess"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your address here!',
                    },
                  ]}
                >
                  <DataCardInput />
                </Form.Item>
              </DataCard>

              {decodeResult.value && (
                <DecodeResultDiv error={decodeResult.error}>{decodeResult.value}</DecodeResultDiv>
              )}
            </Form>
          </DataModal>
          <AppWrapper>
            <MainNavigation
              login={login}
              logOut={this.logOut}
              generateAccount={this.generateAccount}
              handleDecodeAddressMenuClick={this.handleDecodeAddressMenuClick}
              defaultPrivateKey={this.props.defaultPrivateKey.address}
              checkUrlPriv={this.props.checkUrlPriv}
              initTokenRecord={this.props.initTokenRecord}
            />
            <ContentRowWrapper justify="center" gutter={[0, 0]}>
              <Col xs={22} sm={20} md={20} lg={22} xl={20} xxl={18}>
                <Row gutter={[5, 5]}>
                  <Col span={24}>
                    <SearchBar />
                  </Col>
                </Row>
              </Col>
              <Suspense fallback="">
                <Switch>
                  {routes?.length && routes.map((route, idx) => this.renderRoute(route, idx))}
                  <RouteWithBody exact path="/" render={() => <Redirect to="/home" />} />
                </Switch>
              </Suspense>
            </ContentRowWrapper>
          </AppWrapper>
          <FooterComponent />
        </Router>
      </Inspect>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    defaultPrivateKey: state.defaultPrivateKey,
    checkUrlPriv: state.checkUrlPriv,
    tokenRecord: state.tokenRecord,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (id) => {
      dispatch(logout(id))
    },
    loadFromStorage: () => {
      dispatch(loadFromStorage())
    },
    checkUrlPrivFunc: () => {
      dispatch(defaultURLPrivateKey())
    },
    initTokenRecord: () => {
      dispatch(initTokenRecord())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(App)
