import { TRANSFERRC_DEFAULT, TRANSFERRC_LOAD } from '../actions/transferLoadRC10RC20'

export function transferLoadRC10RC20Reducer(state = defaultData, action) {
  switch (action.type) {
    case TRANSFERRC_DEFAULT:
      state = defaultData
      break
    case TRANSFERRC_LOAD:
      state = action.payload
      break
    default:
      break
  }
  return state
}

var defaultData = {
  status: 'requesting',
  data: [],
}
