export const URL_PRIVATE = 'URL_PRIVATE'
export const LOAD_URL_PRIVATE = 'LOAD_URL_PRIVATE'

export function defaultURLPrivateKey() {
  return {
    type: URL_PRIVATE,
  }
}

export function urlAtPriv() {
  return {
    type: LOAD_URL_PRIVATE,
    payload: true,
  }
}
export function urlAtNotPriv() {
  return {
    type: LOAD_URL_PRIVATE,
    payload: false,
  }
}
