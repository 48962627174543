import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const ADDRESSES_INIT = 'ADDRESSES_INIT'
export const ADDRESSES_UPDATE = 'ADDRESSES_UPDATE'

export function initAddresses() {
  return {
    type: ADDRESSES_INIT,
  }
}
export function updateAddresses(addresses) {
  return {
    type: ADDRESSES_UPDATE,
    payload: addresses,
  }
}

export function loadAddresses(offset, limit) {
  return (dispatch) => {
    dispatch(initAddresses())
    fetch(`${API_ADDR}/addresses?num=${offset}&limit=${limit}&sort=desc`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageAddressesTotal(res.data.total_assets + 1))
        dispatch(updatePageAddressesStart(res.data.total_assets))
        dispatch(updateAddresses(res.data.result))
      })
      .catch((err) => {})
  }
}

export const PAGE_ADDRESSES_INIT = 'PAGE_ADDRESSES_INIT'
export const PAGE_ADDRESSES_UPDATE = 'PAGE_ADDRESSES_UPDATE'
export const PAGE_ADDRESSES_TOTAL_UPDATE = 'PAGE_ADDRESSES_TOTAL_UPDATE'
export const PAGE_ADDRESSES_LIMIT_UPDATE = 'PAGE_ADDRESSES_LIMIT_UPDATE'
export const PAGE_ADDRESSES_START_UPDATE = 'PAGE_ADDRESSES_START_UPDATE'

export function initPageAddresses() {
  return {
    type: PAGE_ADDRESSES_INIT,
  }
}
export function updatePageAddresses(page) {
  return {
    type: PAGE_ADDRESSES_UPDATE,
    payload: page,
  }
}

export function updatePageAddressesTotal(total) {
  return {
    type: PAGE_ADDRESSES_TOTAL_UPDATE,
    payload: total,
  }
}

export function updatePageAddressesLimit(limit) {
  return {
    type: PAGE_ADDRESSES_LIMIT_UPDATE,
    payload: limit,
  }
}

export function updatePageAddressesStart(start_page) {
  return {
    type: PAGE_ADDRESSES_START_UPDATE,
    payload: start_page,
  }
}
