import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const GET_REWARD_REQUEST = 'GET_REWARD_REQUEST'
export const GET_REWARD_SUCCESS = 'GET_REWARD_SUCCESS'
export const GET_REWARD_FAIL = 'GET_REWARD_FAIL'

export function getRewardRequest() {
  return {
    type: GET_REWARD_REQUEST,
  }
}
export function getRewardSuccess(payload) {
  return {
    type: GET_REWARD_SUCCESS,
    payload,
  }
}
export function getRewardFail() {
  return {
    type: GET_REWARD_FAIL,
  }
}

export function getReward(payload) {
  return (dispatch) => {
    dispatch(getRewardRequest())
    fetch(`${API_ADDR}/account/getreward`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getRewardSuccess(res.data))
      })
      .catch(() => dispatch(getRewardFail()))
  }
}

export const WITHDRAW_REWARD_REQUEST = 'WITHDRAW_REWARD_REQUEST'
export const WITHDRAW_REWARD_SUCCESS = 'WITHDRAW_REWARD_SUCCESS'
export const WITHDRAW_REWARD_FAIL = 'WITHDRAW_REWARD_FAIL'

export function withdrawRewardRequest() {
  return {
    type: WITHDRAW_REWARD_REQUEST,
  }
}
export function withdrawRewardSuccess(payload) {
  return {
    type: WITHDRAW_REWARD_SUCCESS,
    payload,
  }
}
export function withdrawRewardFail(payload) {
  return {
    type: WITHDRAW_REWARD_FAIL,
    payload,
  }
}

export function withdrawReward(payload) {
  return (dispatch) => {
    dispatch(withdrawRewardRequest())
    fetch(`${API_ADDR}/account/withdrawreward`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        res.status === 'success'
          ? dispatch(withdrawRewardSuccess(res.data))
          : dispatch(withdrawRewardFail(res.message))
      })
      .catch(() => dispatch(withdrawRewardFail('Failed')))
  }
}
