import {
  GET_DAILY_TRANSACTIONS_CHART,
  GET_DAILY_TRANSACTIONS_CHART_SUCCESS,
  GET_CUMULATIVE_TRANSACTIONS_CHART,
  GET_CUMULATIVE_TRANSACTIONS_CHART_SUCCESS,
  GET_STAKING_RATE_CHART,
  GET_STAKING_RATE_CHART_SUCCESS,
} from '../actions/charts'

const initCharts = {
  dailyTransactions: [],
  cumulativeTransactions: [],
  stakingRate: [],
}

export function chartsReducer(state = initCharts, action) {
  switch (action.type) {
    case GET_DAILY_TRANSACTIONS_CHART_SUCCESS:
      state = { ...state, dailyTransactions: action.payload }
      break
    case GET_DAILY_TRANSACTIONS_CHART:
      break
    case GET_CUMULATIVE_TRANSACTIONS_CHART_SUCCESS:
      state = { ...state, cumulativeTransactions: action.payload }
      break
    case GET_CUMULATIVE_TRANSACTIONS_CHART:
      break
    case GET_STAKING_RATE_CHART_SUCCESS:
      state = { ...state, stakingRate: action.payload }
      break
    case GET_STAKING_RATE_CHART:
      break

    default:
      break
  }
  return state
}
