import { notification } from 'antd'
import { GetPrivateKeyByAddress } from '../common/validate'
import { API_ADDR } from '../config/config'

export const ISSUE_TRC10_NONE = 'ISSUE_TRC10_NONE'
export const ISSUE_TRC10_REQUESTING = 'ISSUE_TRC10_REQUESTING'
export const ISSUE_TRC10_SUCCESS = 'ISSUE_TRC10_SUCCESS'
export const ISSUE_TRC10_FAIL = 'ISSUE_TRC10_FAIL'

export function request() {
  return { type: ISSUE_TRC10_REQUESTING }
}
export function fail(message) {
  return {
    type: ISSUE_TRC10_FAIL,
    payload: message,
  }
}
export function reset() {
  return { type: ISSUE_TRC10_NONE }
}
export function success(tranID) {
  return {
    type: ISSUE_TRC10_SUCCESS,
    payload: {
      tranID: tranID,
    },
  }
}

export function issueTRC10(
  privs,
  owner_address,
  name,
  desc,
  abbr,
  url,
  precision,
  totalSupply,
  startTime,
  endTime,
  freeAssetNetLimit,
  publicFreeAssetNetLimit,
  trxNum,
  icoNum
) {
  return async (dispatch) => {
    dispatch(request())
    var privKey = GetPrivateKeyByAddress(privs, owner_address)

    fetch(`${API_ADDR}/issuetoken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        owner_address: owner_address,
        name: name,
        abbr: abbr,
        total_supply: totalSupply,
        trx_num: trxNum,
        num: icoNum,
        start_time: String(startTime),
        end_time: String(endTime),
        description: desc,
        precision: precision,
        url: url,
        free_asset_net_limit: freeAssetNetLimit,
        public_free_asset_net_limit: publicFreeAssetNetLimit,
        private_key: privKey,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          dispatch(fail(res.message))
          notification.error({
            message: res.message,
            description: `Failed`,
          })
        } else if (res.status === 'success') {
          if (res.data.result === true) {
            dispatch(success(res.data.txid))
            notification.success({
              message: 'TxID: ' + res.data.txid,
              description: `Succeeded`,
            })
          } else {
            dispatch(fail('Freeze balance failed'))
            notification.error({
              message: 'Transfer ' + res.data.txid,
              description: `Failed`,
            })
          }
        }
      })
      .catch((err) => {
        dispatch(fail(err))
        notification.error({
          message: err,
          description: `Failed`,
        })
      })
  }
}
