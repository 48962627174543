import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const TOP_TOKEN_HOLDERS_INIT = 'TOP_TOKEN_HOLDERS_INIT'
export const TOP_TOKEN_HOLDERS_UPDATE = 'TOP_TOKEN_HOLDERS_UPDATE'

export function initTopTokenHolders() {
  return {
    type: TOP_TOKEN_HOLDERS_INIT,
  }
}
export function updateTopTokenHolders(topTokenHolders) {
  return {
    type: TOP_TOKEN_HOLDERS_UPDATE,
    payload: topTokenHolders,
  }
}

export function loadTopTokenHolders(address) {
  return (dispatch) => {
    dispatch(initTopTokenHolders())
    fetch(`${API_ADDR}/tokenholder/top/${address}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateTopTokenHolders(res.data))
      })
      .catch((err) => {})
  }
}
