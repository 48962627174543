import {
  AccountBookOutlined,
  AppstoreOutlined,
  BlockOutlined,
  DeploymentUnitOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
  KeyOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  NodeIndexOutlined,
  PlusCircleOutlined,
  RedoOutlined,
  SendOutlined,
  SplitCellsOutlined,
  TransactionOutlined,
  UserOutlined,
  WalletOutlined,
  LikeOutlined,
  UserSwitchOutlined,
  CalculatorOutlined,
  FilePdfOutlined,
  LineChartOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { Avatar, Col, Menu, Row, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const { SubMenu } = Menu

const Logo = styled.img`
  height: ${(height) => height || 40}px;
  width: ${(width) => width || 120}px;
`

const RightMenuItem = styled(({ last, ...props }) => <Menu.Item {...props} />)`
  float: right;
  padding-left: 10px !important;
  padding-right: 10px !important;
  span a {
    color: ${({ theme }) => theme.colors.blue_2} !important;
  }
  ${({ last }) =>
    !last
      ? `
    position: relative;
    :before {
      content: ' ';
      display: block;
      position: absolute;
      right: 0;
      width: 1px;
      height: 10px;
      background: #74cfff;
      top: 36px;
    }
  `
      : ``}
`

const RightSubMenuItem = styled(SubMenu)`
  float: right;
`
const LeftSubMenuItem = styled(SubMenu)`
  float: left;
`
const MenuDefaultKey = styled(Menu)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const MenuItemStyled = styled(Menu.Item)`
  white-space: normal;
  height: auto !important;
`
const MainNavigationContainer = styled(Row)`
  background: ${({ theme }) => theme.colors.primary_blue};
  .ant-menu.ant-menu-dark {
    background: transparent;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const LogoItem = styled(Menu.Item)`
  padding-left: 0 !important;
`
const AvatarStyled = styled(Avatar)`
  background: #fcb03f;
`

const PrimaryButton = styled(Link)`
  background: ${({ theme }) => theme.colors_v2.primary_yellow};
  height: 40px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors_v2.text_dark};
  font-weight: 500;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 90px;
  border: 2px solid ${({ theme }) => theme.colors_v2.primary_yellow};
  &:hover {
    color: ${({ theme }) => theme.colors_v2.white};
  }
`

const OutlineButton = styled(Link)`
  color: ${({ theme }) => theme.colors_v2.white};
  height: 40px;
  line-height: 36px;
  border-radius: 90px;
  border: 2px solid #312d70;
  padding: 8px 16px;
  margin-left: 6px;
`

const MainNavigationDesktop = ({
  login,
  logOut,
  generateAccount,
  handleDecodeAddressMenuClick,
  defaultPrivateKey,
  checkUrlPriv,
  initTokenRecord,
}) => {
  const isLoggedIn = login.token !== ''
  const { xs } = useBreakpoint()

  const [selectedKeys, setSelectedKeys] = useState({ menu1: '', menu2: '', menu3: '' })
  const [activeKey, setActiveKey] = useState('')

  const location = useLocation()
  const key = location.pathname.split('/')[1]
  if (key !== activeKey) {
    setActiveKey(key)
  }

  return (
    <MainNavigationContainer justify="center" gutter={[0, 0]}>
      <Col xs={24} sm={20} md={20} lg={22} xl={20} xxl={18}>
        <Row wrap={false} justify="space-between" align="middle">
          <Col flex="auto">
            <Menu
              mode="horizontal"
              theme="dark"
              overflowedIndicator="More"
              selectedKeys={selectedKeys.menu1}
              activeKey={activeKey}
              onSelect={({ keyPath, selectedKeys }) => {
                if (keyPath[1] !== 'more') {
                  setSelectedKeys({ menu1: selectedKeys, menu2: '', menu3: '' })
                }
                return
              }}
            >
              <LogoItem disabled={false} key="logo-home">
                <Link to="/">
                  <Logo src="/logo.png" width={xs ? 100 : 120} height={xs ? 35 : 40} />
                </Link>
              </LogoItem>
              {!xs ? (
                <Menu.Item key="home">
                  <Link to="/home">Home</Link>
                </Menu.Item>
              ) : null}
              <SubMenu
                key="blockchain-info"
                title={
                  <>
                    <span>Blockchain</span>
                  </>
                }
              >
                <Menu.Item key="witness" icon={<AppstoreOutlined />}>
                  <Link to="/witness">Witnesses</Link>
                </Menu.Item>
                <Menu.Item key="blocks" icon={<BlockOutlined />}>
                  <Link to="/blocks">Blocks</Link>
                </Menu.Item>
                <Menu.Item key="nodes" icon={<NodeIndexOutlined />}>
                  <Link to="/nodes">Nodes</Link>
                </Menu.Item>
                <Menu.Item key="transactions" icon={<TransactionOutlined />}>
                  <Link to="/transactions">Transactions</Link>
                </Menu.Item>
                <Menu.Item key="accounts" icon={<AccountBookOutlined />}>
                  <Link to="/accounts">Accounts</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub-menu-tokens"
                title={
                  <>
                    <span>Tokens</span>
                  </>
                }
              >
                <Menu.Item key="tokens" icon={<DollarCircleOutlined />}>
                  <Link to="/tokens">All Tokens</Link>
                </Menu.Item>
                <Menu.Item key="contracts" icon={<FileTextOutlined />}>
                  <Link to="/contracts">Smart Contracts</Link>
                </Menu.Item>
                <Menu.Item key="token-record" icon={<PlusCircleOutlined />}>
                  <Link
                    to="/token-record/create/Type"
                    onClick={() => {
                      initTokenRecord()
                    }}
                  >
                    Token Record
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub-menu-governance" title="Governance">
                <Menu.Item key="representatives" icon={<UserSwitchOutlined />}>
                  <Link to="/representatives">Representatives</Link>
                </Menu.Item>
                <Menu.Item key="votes" icon={<LikeOutlined />}>
                  <Link to="/votes">Votes</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="tools"
                title={
                  <>
                    <span>Tools</span>
                  </>
                }
              >
                <MenuItemStyled
                  key="generateAddress"
                  icon={<PlusCircleOutlined />}
                  onClick={generateAccount}
                >
                  Generate Account
                </MenuItemStyled>
                <MenuItemStyled
                  key="decodeAddress"
                  icon={<RedoOutlined />}
                  onClick={handleDecodeAddressMenuClick}
                >
                  Decode Address
                </MenuItemStyled>
              </SubMenu>
              <SubMenu
                key="data"
                title={
                  <>
                    <span>Data</span>
                  </>
                }
              >
                <MenuItemStyled key="charts" icon={<LineChartOutlined />}>
                  <Link to="/data/transactions">Charts</Link>
                </MenuItemStyled>
              </SubMenu>
              <SubMenu key="more" title="More" onMouseLeave={() => setActiveKey('')}>
                <Menu.Item key="technicalDocs" icon={<FileTextOutlined />}>
                  <a href="http://developers.welscan.io" target="_blank">
                    Technical Docs
                  </a>
                </Menu.Item>
                <Menu.Item key="station" icon={<CalculatorOutlined />}>
                  <a href="https://station.welscan.io" target="_blank">
                    Station
                  </a>
                </Menu.Item>
                <Menu.Item key="whitepaper" icon={<FilePdfOutlined />}>
                  <a href="https://welups.com/Upload/pdf/Welups_WhitePaper_EN.pdf" target="_blank">
                    Whitepaper
                  </a>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
          {defaultPrivateKey !== undefined ? (
            <Col style={{ minWidth: '190px' }}>
              <MenuDefaultKey
                mode="horizontal"
                theme="dark"
                selectedKeys={selectedKeys.menu2}
                onSelect={({ selectedKeys }) =>
                  setSelectedKeys({ menu1: '', menu2: selectedKeys, menu3: '' })
                }
              >
                <LeftSubMenuItem
                  key="sub-menu-add"
                  title={
                    defaultPrivateKey.substring(0, 5) +
                    '...' +
                    defaultPrivateKey.substring(
                      defaultPrivateKey.length - 3,
                      defaultPrivateKey.length
                    )
                  }
                >
                  <Menu.Item key="accounts">
                    <Link
                      to={'/accounts/' + (defaultPrivateKey === undefined ? '' : defaultPrivateKey)}
                    >
                      {defaultPrivateKey.substring(0, 10) +
                        '...' +
                        defaultPrivateKey.substring(
                          defaultPrivateKey.length - 10,
                          defaultPrivateKey.length
                        )}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="prive" hidden={checkUrlPriv}>
                    <Link to="/prikey-management">Change wallet</Link>
                  </Menu.Item>
                </LeftSubMenuItem>
              </MenuDefaultKey>
            </Col>
          ) : (
            ''
          )}
          <Col>
            {isLoggedIn ? (
              <Menu
                mode={'horizontal'}
                theme="dark"
                selectedKeys={selectedKeys.menu3}
                onSelect={({ selectedKeys }) =>
                  setSelectedKeys({ menu1: '', menu2: '', menu3: selectedKeys })
                }
              >
                <RightSubMenuItem
                  title={<AvatarStyled icon={<UserOutlined />} />}
                  key="right-sub-menu-item"
                >
                  <Menu.Item key="key" style={{ color: 'white!important' }} disabled>
                    {localStorage.getItem('user') ? (
                      <>
                        <Tooltip placement="top" title={localStorage.getItem('user')}>
                          <p>
                            {localStorage.getItem('user').length > 21
                              ? localStorage.getItem('user').slice(0, 7) +
                                '*******' +
                                localStorage
                                  .getItem('user')
                                  .slice(
                                    localStorage.getItem('user').length - 7,
                                    localStorage.getItem('user').length
                                  )
                              : localStorage.getItem('user')}
                          </p>
                        </Tooltip>
                      </>
                    ) : (
                      ''
                    )}
                  </Menu.Item>
                  {localStorage.getItem('user') ? <hr /> : ''}
                  <Menu.Item key="wallet" icon={<WalletOutlined />}>
                    <Link to="/wallet">Assets</Link>
                  </Menu.Item>
                  <Menu.Item key="transfer" icon={<SendOutlined />}>
                    <Link to="/user/transferasset">Send</Link>
                  </Menu.Item>
                  <Menu.Item key="transferwrc20" icon={<SendOutlined />}>
                    <Link to="/user/transferwrc20">Transfer WRC20</Link>
                  </Menu.Item>
                  <Menu.Item key="issueTokenWRC10" icon={<MoneyCollectOutlined />}>
                    <Link to="/user/issue-token-wrc10">Issue WRC10</Link>
                  </Menu.Item>
                  <Menu.Item key="deployContract" icon={<DeploymentUnitOutlined />}>
                    <Link to="/user/deploycontract">Deploy Contract</Link>
                  </Menu.Item>
                  <Menu.Item key="freezeBalance" icon={<SplitCellsOutlined />}>
                    <Link to="/user/freeze-balance">Freeze Balance</Link>
                  </Menu.Item>
                  <Menu.Item key="prikeyManagement" icon={<KeyOutlined />}>
                    <Link to="/prikey-management">Private Key Management</Link>
                  </Menu.Item>
                  <Menu.Item key="logOut" icon={<LogoutOutlined />}>
                    <Link to="/login" onClick={logOut}>
                      Log out
                    </Link>
                  </Menu.Item>
                </RightSubMenuItem>
              </Menu>
            ) : (
              <>
                <Row align="middle" wrap={false}>
                  <Col>
                    <PrimaryButton to="/signup">Register</PrimaryButton>
                  </Col>
                  <Col>
                    <OutlineButton to="/login">Login</OutlineButton>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </MainNavigationContainer>
  )
}
export default MainNavigationDesktop
