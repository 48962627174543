import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'
import Account from '../api/account'
import { updateAccountDetails } from './account'
import { logout } from './login'
export const ASSET_INIT = 'ASSET_INIT'
export const ASSET_UPDATE = 'ASSET_UPDATE'
export const ADDRESSES_UPDATE = 'ADDRESSES_UPDATE'
export const ASSET_NEW_ADDR = 'ASSET_NEW_ADDR'
export const ADDRESS_REQUESTING = 'ADDRESS_REQUESTING'
export const ASSETS_REQUESTING = 'ASSETS_REQUESTING'
export const REQUEST_FAILED = 'REQUEST_FAILED'
export function initAsset() {
  return {
    type: ASSET_INIT,
  }
}
export function requestAsset() {
  return {
    type: ASSETS_REQUESTING,
  }
}
export function fail() {
  return {
    type: REQUEST_FAILED,
  }
}
export function updateAsset(assets, addr) {
  return {
    type: ASSET_UPDATE,
    payload: {
      assets: assets,
      addr: addr,
    },
  }
}

export function updateAddresses(addresses) {
  return {
    type: ADDRESSES_UPDATE,
    payload: addresses,
  }
}

export function newAddress(addr) {
  return {
    type: ASSET_NEW_ADDR,
    payload: addr,
  }
}
export function addAddrFromPrvkey(id, token, privateKey) {
  return async (dispatch) => {
    const addr = Account.addressFromPrivateKey(privateKey)
    if (id === undefined) {
      fetch(`${API_ADDR}/useridshare/inseraddress`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          access_token: token,
          address: addr,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 'success':
              dispatch(newAddress(addr))
              break
            case 'fail':
            case 'error':
              break
            default:
              break
          }
        })
        .catch((err) => {
          dispatch(logout(id))
        })
    } else {
      const res = await fetch(`${API_ADDR}/users/${id}/addresses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
        mode: 'cors',
        body: JSON.stringify({
          address: addr,
        }),
      })
      if (!res.ok) {
        dispatch(logout(id))
        return
      }
      const result = await res.json()
      switch (result.status) {
        case 'success':
          dispatch(newAddress(addr))
          break
        case 'fail':
        case 'error':
          break
        default:
          break
      }
    }
  }
}
export function loadAssetDetails(addr) {
  return async (dispatch) => {
    dispatch(requestAsset())
    const res = await fetch(`${API_ADDR}/accounts/${addr}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
    })
    if (!res.ok) {
      dispatch(fail())
      return undefined
    }
    const result = await res.json()
    if (result.status === 'success') {
      dispatch(updateAsset(result.data, addr))
    }
  }
}

export function loadAssetApi(id, token) {
  return async (dispatch) => {
    dispatch(request())
    if (id === undefined) {
      fetch(`${API_ADDR}/getaccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          access_token: token,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          localStorage.setItem('user', res.data.email)
          dispatch(
            updateAccountDetails({
              name: res.data.email,
              address: res.data.email,
              totalBalance: 0,
              acgBalance: 0,
              transactions: 0,
              transferred: 0,
              availableBalance: 0,
              netUsage: 0,
              bandwidthUsed: 0,
              bandwidthTotal: 0,
              updateTime: 0,
              frozenBalance: 0,
              energyTotal: 0,
              energyUsed: 0,
              asset: {},
            })
          )
          let addresses = {}
          for (let index = 0; index < res.data.addresses.length; index++) {
            const addr = res.data.addresses[index]
            addresses[addr] = null
          }
          dispatch(updateAddresses(addresses))
        })
        .catch((err) => {
          dispatch(logout(id))
          return
        })
    } else {
      const res = await fetch(`${API_ADDR}/users/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
        mode: 'cors',
      })
      if (!res.ok) {
        dispatch(logout(id))
        return
      }
      const result = await res.json()

      switch (result.status) {
        case 'success':
          localStorage.setItem('user', result.data.email)
          let addresses = {}
          for (let index = 0; index < result.data.addresses.length; index++) {
            const addr = result.data.addresses[index]
            addresses[addr] = null
          }
          dispatch(updateAddresses(addresses))
          break
        case 'fail':
        case 'error':
          break
        default:
          break
      }
    }
  }
}
export function request(assets) {
  return {
    type: ADDRESS_REQUESTING,
    payload: assets,
  }
}
