import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const BECOME_WITNESS_REQUEST = 'BECOME_WITNESS_REQUEST'
export const BECOME_WITNESS_SUCCESS = 'BECOME_WITNESS_SUCCESS'
export const BECOME_WITNESS_FAIL = 'BECOME_WITNESS_FAIL'

export function request() {
  return {
    type: BECOME_WITNESS_REQUEST,
  }
}
export function success(payload) {
  return {
    type: BECOME_WITNESS_SUCCESS,
    payload,
  }
}
export function fail(payload) {
  return {
    type: BECOME_WITNESS_FAIL,
    payload,
  }
}

export function loadBecomeWitness(payload) {
  return (dispatch) => {
    dispatch(request())
    fetch(`${API_ADDR}/witness`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        res.status === 'success' ? dispatch(success(res.data)) : dispatch(fail(res.message))
      })
      .catch((err) => console.log(err))
  }
}
