import {
  ADDRESSES_INIT,
  ADDRESSES_UPDATE,
  PAGE_ADDRESSES_INIT,
  PAGE_ADDRESSES_UPDATE,
  PAGE_ADDRESSES_TOTAL_UPDATE,
  PAGE_ADDRESSES_LIMIT_UPDATE,
  PAGE_ADDRESSES_START_UPDATE,
} from '../actions/addresses'

const initAddresses = []

export function addressesReducer(state = initAddresses, action) {
  switch (action.type) {
    case ADDRESSES_UPDATE:
      return action.payload
    case ADDRESSES_INIT:
      return initAddresses
    default:
      return state
  }
}

var initPageAddresses = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 10000,
  current_page: 1,
}

export function pageAddressesReducer(state = initPageAddresses, action) {
  switch (action.type) {
    case PAGE_ADDRESSES_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_ADDRESSES_INIT:
      return initPageBlocks
    case PAGE_ADDRESSES_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_ADDRESSES_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_ADDRESSES_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
