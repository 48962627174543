import {
  TOKEN_HOLDERS_INIT,
  TOKEN_HOLDERS_UPDATE,
  PAGE_TOKEN_HOLDERS_INIT,
  PAGE_TOKEN_HOLDERS_UPDATE,
  PAGE_TOKEN_HOLDERS_TOTAL_UPDATE,
  PAGE_TOKEN_HOLDERS_LIMIT_UPDATE,
  PAGE_TOKEN_HOLDERS_START_UPDATE,
} from '../actions/tokenHolders'

const initTokenHolders = []

export function tokenHoldersReducer(state = initTokenHolders, action) {
  switch (action.type) {
    case TOKEN_HOLDERS_UPDATE:
      return action.payload
    case TOKEN_HOLDERS_INIT:
      return initTokenHolders
    default:
      return state
  }
}

var initPageTokenHolders = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 0,
  current_page: 1,
}

export function pageTokenHoldersReducer(state = initPageTokenHolders, action) {
  switch (action.type) {
    case PAGE_TOKEN_HOLDERS_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_TOKEN_HOLDERS_INIT:
      state.start_page = 1
      state.start_item = 0
      state.page_limit = 20
      state.total_items = 0
      state.current_page = 1
      return state
    case PAGE_TOKEN_HOLDERS_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_TOKEN_HOLDERS_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_TOKEN_HOLDERS_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
