import { API_ADDR } from '../config/config'
import { GetPrivateKeyByAddress } from '../common/validate'
import { notification } from 'antd'
import { TransferWRC20 } from '../common/transfer'

export const TRANSFER_WRC20_UPDATE = 'TRANSFER_WRC20_UPDATE'
export const TRANSFER_WRC20_INIT = 'TRANSFER_WRC20_INIT'
export const TRANSFER_WRC20_REQUESTING = 'TRANSFER_WRC20_REQUESTING'
export const TRANSFER_WRC20_NONE = 'TRANSFER_WRC20_NONE'
export const TRANSFER_WRC20_SUCCESS = 'TRANSFER_WRC20_SUCCESS'
export const TRANSFER_WRC20_FAIL = 'TRANSFER_WRC20_FAIL'

export function reset() {
  return {
    type: TRANSFER_WRC20_NONE,
  }
}
export function request() {
  return {
    type: TRANSFER_WRC20_REQUESTING,
  }
}
export function initTransferWRC20() {
  return {
    type: TRANSFER_WRC20_INIT,
  }
}

export function updateTransferWRC20(data) {
  return {
    type: TRANSFER_WRC20_UPDATE,
    payload: data,
  }
}

export function successTransferWRC20(tranID) {
  return {
    type: TRANSFER_WRC20_SUCCESS,
    payload: tranID,
  }
}

export function failTransferWRC20(message) {
  return {
    type: TRANSFER_WRC20_FAIL,
    payload: message,
  }
}

export function transferWRC20(privs, owner_address, to_address, contract_address, amount) {
  return async (dispatch) => {
    dispatch(request())
    var fromPrivKey = GetPrivateKeyByAddress(privs, owner_address)

    var { statusTransfer, transactionID, messageTransfer } = await TransferWRC20(
      fromPrivKey,
      contract_address,
      owner_address,
      to_address,
      amount
    )
    console.log(statusTransfer)
    console.log(transactionID)
    console.log(messageTransfer)
    if (statusTransfer) {
      if (transactionID) {
        dispatch(successTransferWRC20(transactionID))
      } else {
        dispatch(failTransferWRC20(messageTransfer))
      }
    } else {
      dispatch(failTransferWRC20(messageTransfer))
    }
  }
}
