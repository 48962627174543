import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const CONTRACT_TRANSACTION_INIT = 'CONTRACT_TRANSACTION_INIT'
export const CONTRACT_TRANSACTION_UPDATE = 'CONTRACT_TRANSACTION_UPDATE'

export function initContractTransaction() {
  return {
    type: CONTRACT_TRANSACTION_INIT,
  }
}
export function updateContractTransaction(transaction, total, status) {
  return {
    type: CONTRACT_TRANSACTION_UPDATE,
    payload: {
      result: transaction,
      total: total,
      status: status,
    },
  }
}

export function loadContractTransaction(address, offset, limit, date) {
  var start = 0
  var end = 0
  if (date != null) {
    if (date.length == 2) {
      start = date[0].valueOf()
      end = date[1].valueOf()
    }
  }

  return (dispatch) => {
    dispatch(initContractTransaction())
    fetch(
      `${API_ADDR}/contract/transactions/${address}?start=${start}&end=${end}&num=${offset}&limit=${limit}&sort=desc`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageContractTransactionTotal(res.data.total + 1))
        dispatch(updatePageContractTransactionStart(res.data.total))
        dispatch(updateContractTransaction(res.data.result, res.data.total, res.status))
      })
      .catch((err) => {})
  }
}

export const PAGE_CONTRACT_TRANSACTION_INIT = 'PAGE_CONTRACT_TRANSACTION_INIT'
export const PAGE_CONTRACT_TRANSACTION_UPDATE = 'PAGE_CONTRACT_TRANSACTION_UPDATE'
export const PAGE_CONTRACT_TRANSACTION_TOTAL_UPDATE = 'PAGE_CONTRACT_TRANSACTION_TOTAL_UPDATE'
export const PAGE_CONTRACT_TRANSACTION_LIMIT_UPDATE = 'PAGE_CONTRACT_TRANSACTION_LIMIT_UPDATE'
export const PAGE_CONTRACT_TRANSACTION_START_UPDATE = 'PAGE_CONTRACT_TRANSACTION_START_UPDATE'

export function initPageContractTransaction() {
  return {
    type: PAGE_CONTRACT_TRANSACTION_INIT,
  }
}
export function updatePageContractTransaction(page) {
  return {
    type: PAGE_CONTRACT_TRANSACTION_UPDATE,
    payload: page,
  }
}

export function updatePageContractTransactionTotal(total) {
  return {
    type: PAGE_CONTRACT_TRANSACTION_TOTAL_UPDATE,
    payload: total,
  }
}

export function updatePageContractTransactionLimit(limit) {
  return {
    type: PAGE_CONTRACT_TRANSACTION_LIMIT_UPDATE,
    payload: limit,
  }
}

export function updatePageContractTransactionStart(start_page) {
  return {
    type: PAGE_CONTRACT_TRANSACTION_START_UPDATE,
    payload: start_page,
  }
}
