import {
  GET_MARKET_INFO_REQUEST,
  GET_MARKET_INFO_SUCCESS,
  GET_MARKET_INFO_FAIL,
} from '../actions/marketInfo'

const initMarketInfo = {
  loading: false,
  marketInfo: null,
  message: null,
}

export function marketInfoReducer(state = initMarketInfo, action) {
  switch (action.type) {
    case GET_MARKET_INFO_REQUEST:
      return { ...state, loading: true, marketInfo: null }
    case GET_MARKET_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        marketInfo: action.payload,
      }
    case GET_MARKET_INFO_FAIL:
      return { ...state, loading: false, marketInfo: null }
    default:
      return state
  }
}
