import { API_ADDR } from '../config/config'
import fetch from 'cross-fetch'
import { GLOBAL_SYMBOL } from '../constant'

export const TRANSFERRC_DEFAULT = 'TRANSFERRC_DEFAULT'
export const TRANSFERRC_LOAD = 'TRANSFERRC_LOAD'

export function defaultTransferRC() {
  return {
    type: TRANSFERRC_DEFAULT,
  }
}

export function loadTransferRC(data) {
  return {
    type: TRANSFERRC_LOAD,
    payload: data,
  }
}

export function loadTransferRCApi(address, type_rc10) {
  return (dispatch) => {
    dispatch(defaultTransferRC())
    if (type_rc10) {
      fetch(`${API_ADDR}/address/getallrc10/${address}`, {
        method: 'GET',
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((res) => {
          var defaultToken = { abbr: GLOBAL_SYMBOL, precision: 6, name: GLOBAL_SYMBOL, id: '' }
          var tokens = []
          tokens.push(defaultToken)
          res.data.map((item) => {
            tokens.push(item)
          })

          res.data = tokens
          dispatch(loadTransferRC(res))
        })
        .catch((err) => {
          dispatch(
            loadTransferRC({
              data: [],
              status: 'fail',
            })
          )
        })
    } else {
      fetch(`${API_ADDR}/address/getallrc20/${address}`, {
        method: 'GET',
        mode: 'cors',
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch(loadTransferRC(res))
        })
        .catch((err) => {
          dispatch(
            loadTransferRC({
              data: [],
              status: 'fail',
            })
          )
        })
    }
  }
}
