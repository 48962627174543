import { DEFAULT_PRIVATE_KEY_DEFAULT, DEFAULT_PRIVATE_KEY_LOAD } from '../actions/defaultPrivateKey'

var dataDefaultPrivateKey = {
  address: undefined,
  priv: undefined,
}

const defaultPrivateKeyState = dataDefaultPrivateKey

export function defaultPrivateKeyReducer(state = [], action) {
  switch (action.type) {
    case DEFAULT_PRIVATE_KEY_DEFAULT:
      state = defaultPrivateKeyState
      break
    case DEFAULT_PRIVATE_KEY_LOAD:
      state = action.payload
      break
  }
  return state
}
