import {
  DeploymentUnitOutlined,
  KeyOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  SendOutlined,
  SplitCellsOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import { Avatar, Menu, Tooltip } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const { SubMenu } = Menu

const RightSubMenuItem = styled(SubMenu)`
  float: right;
`
const AvatarStyled = styled(Avatar)`
  background: #fcb03f;
`

const UserMenu = ({ logOut }) => {
  const [selectedKeys, setSelectedKeys] = useState({ menu1: '', menu2: '', menu3: '' })

  return (
    <Menu
      mode={'horizontal'}
      theme="dark"
      selectedKeys={selectedKeys.menu3}
      onSelect={({ selectedKeys }) =>
        setSelectedKeys({ menu1: '', menu2: '', menu3: selectedKeys })
      }
    >
      <RightSubMenuItem title={<AvatarStyled icon={<UserOutlined />} />} key="right-sub-menu-item">
        <Menu.Item key="key" style={{ color: 'white!important' }} disabled>
          {localStorage.getItem('user') ? (
            <>
              <Tooltip placement="top" title={localStorage.getItem('user')}>
                <p>
                  {localStorage.getItem('user').length > 21
                    ? localStorage.getItem('user').slice(0, 7) +
                      '*******' +
                      localStorage
                        .getItem('user')
                        .slice(
                          localStorage.getItem('user').length - 7,
                          localStorage.getItem('user').length
                        )
                    : localStorage.getItem('user')}
                </p>
              </Tooltip>
            </>
          ) : (
            ''
          )}
        </Menu.Item>
        {localStorage.getItem('user') ? <hr /> : ''}
        <Menu.Item key="wallet" icon={<WalletOutlined />}>
          <Link to="/wallet">Assets</Link>
        </Menu.Item>
        <Menu.Item key="transfer" icon={<SendOutlined />}>
          <Link to="/user/transferasset">Send</Link>
        </Menu.Item>
        <Menu.Item key="transferwrc20" icon={<SendOutlined />}>
          <Link to="/user/transferwrc20">Transfer WRC20</Link>
        </Menu.Item>
        <Menu.Item key="issueTokenWRC10" icon={<MoneyCollectOutlined />}>
          <Link to="/user/issue-token-wrc10">Issue WRC10</Link>
        </Menu.Item>
        <Menu.Item key="deployContract" icon={<DeploymentUnitOutlined />}>
          <Link to="/user/deploycontract">Deploy Contract</Link>
        </Menu.Item>
        <Menu.Item key="freezeBalance" icon={<SplitCellsOutlined />}>
          <Link to="/user/freeze-balance">Freeze Balance</Link>
        </Menu.Item>
        <Menu.Item key="prikeyManagement" icon={<KeyOutlined />}>
          <Link to="/prikey-management">Private Key Management</Link>
        </Menu.Item>
        <Menu.Item key="logOut" icon={<LogoutOutlined />}>
          <Link to="/login" onClick={logOut}>
            Log out
          </Link>
        </Menu.Item>
      </RightSubMenuItem>
    </Menu>
  )
}
export default UserMenu
