import { WITNESSES_INIT, WITNESSES_UPDATE } from '../actions/witnesses'

const initWitnesses = []
export function witnessesReducer(state = initWitnesses, action) {
  switch (action.type) {
    case WITNESSES_UPDATE:
      return action.payload
    case WITNESSES_INIT:
      return initWitnesses
    default:
      return state
  }
}
