const colors = {
  red: 'ff0000',
  red_1: '#e50915',
  red_2: '#c23631',
  red_3: '#db736f',
  red_4: '#c53027',
  red_5: '#d10000',
  black: '#000000',
  black_1: '#333333',
  white: '#ffffff',
  yellow: '#FCB040',
  yellow_1: '#ffde16',
  yellow_2: '#f29100',
  yellow_3: '#e0ae5c',
  yellow_4: '#f4af40',
  pink: '#ff7677',
  pink_1: '#d9534f',
  light_green: '#e1f3e0',
  gray: '#f0f0f0',
  gray_1: '#eeeeee',
  gray_2: '#999999',
  gray_3: '#d8d8d8',
  gray_4: '#666666',
  gray_5: '#dedede',
  gray_6: '#f3f3f3',
  gray_7: '#707070',
  gray_8: '#c9cacb',
  gray_9: '#fafafa',
  gray_10: '#9f9f9f',
  gray_11: '#dddddd',
  gray_12: '#a39f9b',
  green: '#97e9a4',
  green_1: '#07cc10',
  blue: '#0065d9',
  blue_1: '#1890ff',
  blue_2: '#74cfff',
  blue_3: '#319bfd',
  primary: '#190f5d',
  orange: '#DF711B',
  orange_1: '#ef8920',
}

const fontSizes = {
  xxxs: '10px',
  xxs: '12px',
  xs: '13px',
  sm: '14px',
  xxmd: '15px',
  xmd: '16px',
  md: '17px',
  smd: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '25px',
}

const fontSizesTitle = {
  xxs: '36px',
  xs: '40px',
  sm: '45px',
  md: '55px',
  lg: '80px',
}

const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 600,
}

const colors_v2 = {
  primary_blue: '#262261',
  primary_yellow: '#F7C11A',
  white: '#ffffff',
  dark_blue: '#201D56',
  text_dark: '#3A3A3C',
  grey: '#808191',
  grey_1: '#91979D',
  neutral_4: '#777E90',
  neutral_6: '#E6E8EC',
  success: '#4FBF67',
  tag_green: '#3CB09B',
  tag_red: '#D35D64',
  tag_pink: '#D531C4',
  tag_grey: '#CCCCCC',
  green: '#28CE7E',
}

const border_radius = {
  12: '12px',
  16: '16px',
  20: '20px',
  24: '24px',
  40: '40px',
}

export default {
  colors,
  colors_v2,
  fontSizes,
  fontSizesTitle,
  fontWeights,
  border_radius,
}
