import {
  ADDRESS_TOKEN_INIT,
  ADDRESS_TOKEN_UPDATE,
  PAGE_ADDRESS_TOKEN_INIT,
  PAGE_ADDRESS_TOKEN_UPDATE,
  PAGE_ADDRESS_TOKEN_TOTAL_UPDATE,
  PAGE_ADDRES_TOKEN_LIMIT_UPDATE,
  PAGE_ADDRESS_TOKEN_START_UPDATE,
} from '../actions/addressToken'

const initAddressToken = {
  status: '',
  result: [],
}

const nullAddressToken = {
  status: 'success',
  result: [],
}

export function addressTokensReducer(state = initAddressToken, action) {
  switch (action.type) {
    case ADDRESS_TOKEN_UPDATE:
      return action.payload
    case ADDRESS_TOKEN_INIT:
      return initAddressToken
    default:
      return state
  }
}

var initPageAddressToken = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 10000,
  current_page: 1,
}

export function pageAddressTokenReducer(state = initPageAddressToken, action) {
  switch (action.type) {
    case PAGE_ADDRESS_TOKEN_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_ADDRESS_TOKEN_INIT:
      return initPageAddressToken
    case PAGE_ADDRESS_TOKEN_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_ADDRES_TOKEN_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_ADDRESS_TOKEN_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
