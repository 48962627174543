import { GetTransaction } from '../common/transaction'
import TronTxDecoder from '@/v2/services/decoder.service'
import { hexToAddress } from '../utils/utils'
import BigNumber from 'bignumber.js'
import { API_ADDR } from '../config/config'

const decoder = new TronTxDecoder({ mainnet: true })

export const TRANSACTION_DETAIL_INIT = 'TRANSACTION_DETAIL_INIT'
export const TRANSACTION_DETAIL_UDPATE = 'TRANSACTION_DETAIL_UDPATE'

export function initTransactionDetail() {
  return {
    type: TRANSACTION_DETAIL_INIT,
  }
}
export function updateTransactionDetails(tran) {
  return {
    type: TRANSACTION_DETAIL_UDPATE,
    payload: tran,
  }
}

export function loadTransactionDetails(txHash, timeToTry = 5) {
  return async (dispatch) => {
    dispatch(initTransactionDetail())
    var { status, data } = await GetTransaction(txHash, timeToTry)

    if (status) {
      dispatch(updateTransactionDetails(data))
    } else {
      window.location.href = '/notfound'
    }
  }
}

export const DECODE_INIT = 'DECODE_INIT'
export const DECODE_UPDATE = 'DECODE_UPDATE'

export function initDecode() {
  return {
    type: DECODE_INIT,
  }
}
export function updateDecode(payload) {
  return {
    type: DECODE_UPDATE,
    payload,
  }
}
export function decodeData(type, value) {
  if (type.startsWith('uint') && type.endsWith('[]')) {
    const newValue = value.map((item) => String(new BigNumber(item._hex)))
    return newValue
  }

  if (type === 'address[]') {
    const newValue = value.map((item) => hexToAddress(item.replace('0x', '41')))
    return newValue
  }

  if (value._hex) {
    return String(new BigNumber(value._hex))
  }

  if (type === 'address') {
    const address = value.replace('0x', '41')
    return hexToAddress(address)
  }

  return value
}

export function decodeTxHash(txHash) {
  return (dispatch) => {
    dispatch(initDecode())
    decoder.decodeInputById(txHash).then((res) => {
      if (res) {
        const types = res.inputTypes?.map((item, i) => `${item} ${res.inputNames[i]}`).join(', ')
        const arr = []
        for (let i = 0; i < res.inputTypes?.length; i++) {
          const item = {
            name: res.inputNames[i],
            type: res.inputTypes[i],
            data: decodeData(res.inputTypes[i], res.decodedInput[`${i}`]),
          }
          arr.push(item)
        }
        const data = {
          method: res.methodName,
          methodName: `${res.methodName} (${types})`,
          parameter: arr,
          abi: res.abi,
        }
        dispatch(updateDecode(data))
      } else {
        dispatch(initDecode())
      }
    })
  }
}

export const EVENT_LOGS_INIT = 'EVENT_LOGS_INIT'
export const EVENT_LOGS_UPDATE = 'EVENT_LOGS_UPDATE'

export function initEventLogs() {
  return {
    type: EVENT_LOGS_INIT,
  }
}
export function updateEventLogs(payload) {
  return {
    type: EVENT_LOGS_UPDATE,
    payload,
  }
}
export function eventLogs(txHash) {
  return (dispatch) => {
    dispatch(initEventLogs())
    fetch(`https://node-event-query.welscan.io/v1/wel/transactions/${txHash}/events`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateEventLogs(res.data))
      })
      .catch(() => {})
  }
}

export const DECIMAL_CONTRACT_INIT = 'DECIMAL_CONTRACT_INIT'
export const DECIMAL_CONTRACT_UPDATE = 'DECIMAL_CONTRACT_UPDATE'

export function initDecimalContract() {
  return {
    type: DECIMAL_CONTRACT_INIT,
  }
}
export function updateDecimalContract(payload) {
  return {
    type: DECIMAL_CONTRACT_UPDATE,
    payload,
  }
}
export function decimalContract(addrs) {
  return (dispatch) => {
    dispatch(initDecimalContract())
    const newAddrs = [...new Set(addrs)]
    const requests = newAddrs.map((addr) => fetch(`${API_ADDR}/contracts/${addr}`))
    Promise.all(requests)
      .then((res) => Promise.all(res.map((r) => r.json())))
      .then((res) => {
        if (res) {
          const arr = []
          for (let i = 0; i < res.length; i++) {
            const item = {
              contractAddress: res[i].data.addr,
              decimal: res[i].data.decimals,
              symbol: res[i].data.symbol_token,
            }
            arr.push(item)
          }
          dispatch(updateDecimalContract(arr))
        }
      })
      .catch(() => {})
  }
}
