import {
  ALL_TOKENS_INIT,
  ALL_TOKENS_UPDATE,
  PAGE_ALL_TOKENS_INIT,
  PAGE_ALL_TOKENS_UPDATE,
  PAGE_ALL_TOKENS_TOTAL_UPDATE,
  PAGE_ALL_TOKENS_LIMIT_UPDATE,
} from '../actions/allTokens'

const initAllTokens = null

export function allTokensReducer(state = initAllTokens, action) {
  switch (action.type) {
    case ALL_TOKENS_UPDATE:
      return action.payload
    case ALL_TOKENS_INIT:
      return initAllTokens
    default:
      return state
  }
}

var initPageAllTokens = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 0,
  current_page: 1,
}

export function pageAllTokensReducer(state = initPageAllTokens, action) {
  switch (action.type) {
    case PAGE_ALL_TOKENS_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_ALL_TOKENS_INIT:
      state.start_page = 1
      state.start_item = 0
      state.page_limit = 20
      state.total_items = 0
      state.current_page = 1
      return state
    case PAGE_ALL_TOKENS_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_ALL_TOKENS_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    default:
      return state
  }
}
