import { RESOURCES_REQUEST, RESOURCES_SUCCESS, RESOURCES_FAIL } from '../actions/resources'

const initResources = {
  loading: false,
  resources: [],
  message: null,
}

export function resourcesReducer(state = initResources, action) {
  switch (action.type) {
    case RESOURCES_REQUEST:
      return { ...state, loading: true, resources: [], message: null }
    case RESOURCES_SUCCESS:
      return { ...state, loading: false, resources: action.payload }
    case RESOURCES_FAIL:
      return { ...state, loading: false, resources: [], message: action.payload }
    default:
      return state
  }
}
