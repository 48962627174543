import { Col, Collapse, Drawer } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import '@/styles/v2/menu-mobile.less'
import UserMenu from './UserMenu'
const { Panel } = Collapse

const MenuWrapper = styled(Col)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`
const MenuHeader = styled.div`
  display: flex;
  padding: 22px 18px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10002;
  background: ${({ theme }) => theme.colors_v2.primary_blue};
  height: 70px;
`
const Logo = styled.img`
  width: 95px;
  height: auto;
`
const MenuDrawerIcon = styled.img`
  width: 24px;
  height: 24px;
`

const MenuContent = styled.div`
  background: ${({ theme }) => theme.colors_v2.primary_blue};
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  overflow-y: auto;
  padding-top: 70px;
`
const AuthBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 14px;
`

const Button = styled(Link)`
  height: 40px;
  line-height: 36px;
  font-size: 14px;
  border-radius: 90px;
  text-align: center;
  font-weight: bold;
  border: 2px solid transparent;
`
const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.colors_v2.primary_yellow};
  color: ${({ theme }) => theme.colors_v2.text_dark};
  flex: 1;
  border-color: ${({ theme }) => theme.colors_v2.primary_yellow};
  &:hover {
    color: ${({ theme }) => theme.colors_v2.white};
  }
`

const OutlineButton = styled(Button)`
  color: ${({ theme }) => theme.colors_v2.white};
  border-color: #312d70;
  flex: 1;
`
const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const MenuCollapse = styled(Collapse)`
  border-radius: 0;
  background: transparent;
  border: none;
`
const MenuGroup = styled(Panel)`
  background: ${({ theme }) => theme.colors_v2.primary_blue};
  border: none;
  border-color: rgba(255, 255, 255, 0.1) !important;
  &:last-child {
    border-radius: 0px !important;
  }
  .ant-collapse-header {
    color: ${({ theme }) => theme.colors_v2.white};
  }
`

const MenuItem = styled.div`
  padding: 12px 5px;
  border: none;
  font-weight: bold;
  display: block;
  color: ${({ theme }) => theme.colors_v2.white};
  &.active {
    color: ${({ theme }) => theme.colors_v2.primary_yellow};
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const BlankLink = styled.a`
  color: ${({ theme }) => theme.colors_v2.white};
`

const MainNavigationMobile = ({
  login,
  logOut,
  generateAccount,
  handleDecodeAddressMenuClick,
  initTokenRecord,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const isLoggedIn = login.token !== ''
  const location = useLocation()

  let drawerIcon = '/menu-mobile-icon.png'
  if (isOpened) {
    drawerIcon = '/menu-close-icon.png'
  }

  const handleToggleMenu = () => {
    setIsOpened(!isOpened)
  }

  useEffect(() => {
    setIsOpened(false)
  }, [location])

  return (
    <MenuWrapper className="menu-wrapper">
      <MenuHeader>
        <Link to="/">
          <Logo src="/logo.png" />
        </Link>
        <HeaderRight>
          {isLoggedIn && <UserMenu logOut={logOut} />}
          <MenuDrawerIcon src={drawerIcon} onClick={handleToggleMenu} />
        </HeaderRight>
      </MenuHeader>
      <Drawer
        className="menu-wrapper"
        width="100%"
        title=""
        placement="right"
        visible={isOpened}
        onClose={() => setIsOpened(false)}
      >
        <MenuContent>
          {!isLoggedIn && (
            <AuthBlock>
              <PrimaryButton to="/signup">Register</PrimaryButton>
              <OutlineButton to="/login">Login</OutlineButton>
            </AuthBlock>
          )}

          <MenuList>
            <MenuItem as={Link} to="/home" style={{ padding: '15px 20px' }}>
              Home
            </MenuItem>
            <MenuCollapse
              accordion
              bordered={false}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <DownOutlined style={{ fontWeight: 'bold' }} rotate={isActive ? 180 : 0} />
              )}
            >
              <MenuGroup header="Blockchain">
                <MenuItem as={Link} to="/witness">
                  Witnesses
                </MenuItem>
                <MenuItem as={Link} to="/blocks">
                  Blocks
                </MenuItem>
                <MenuItem as={Link} to="/nodes">
                  Nodes
                </MenuItem>
                <MenuItem as={Link} to="/transactions">
                  Transactions
                </MenuItem>
                <MenuItem as={Link} to="/accounts">
                  Accounts
                </MenuItem>
              </MenuGroup>
              <MenuGroup header="Tokens">
                <MenuItem as={Link} to="/tokens">
                  All Tokens
                </MenuItem>
                <MenuItem as={Link} to="/contracts">
                  Smart Contract
                </MenuItem>
                <MenuItem as={Link} to="/token-record/create/Type" onClick={initTokenRecord}>
                  Token Record
                </MenuItem>
              </MenuGroup>
              <MenuGroup header="Governance">
                <MenuItem as={Link} to="/representatives">
                  Representatives
                </MenuItem>
                <MenuItem as={Link} to="/votes">
                  Votes
                </MenuItem>
              </MenuGroup>
              <MenuGroup header="Tools">
                <MenuItem onClick={generateAccount}>Generate Account</MenuItem>
                <MenuItem onClick={handleDecodeAddressMenuClick}>Decode Address</MenuItem>
              </MenuGroup>
              <MenuGroup header="Data">
                <MenuItem as={Link} to="/data/transactions">
                  Charts
                </MenuItem>
              </MenuGroup>
              <MenuGroup header="More">
                <MenuItem>
                  <BlankLink href="http://developers.welscan.io" target="_blank">
                    Technical Docs
                  </BlankLink>
                </MenuItem>
                <MenuItem>
                  <BlankLink href="https://station.welscan.io" target="_blank">
                    Station
                  </BlankLink>
                </MenuItem>
                <MenuItem>
                  <BlankLink
                    href="https://welups.com/Upload/pdf/Welups_WhitePaper_EN.pdf"
                    target="_blank"
                  >
                    Whitepaper
                  </BlankLink>
                </MenuItem>
              </MenuGroup>
            </MenuCollapse>
          </MenuList>
        </MenuContent>
      </Drawer>
    </MenuWrapper>
  )
}

export default MainNavigationMobile
