import {
  CONTRACT_TRANSACTION_INIT,
  CONTRACT_TRANSACTION_UPDATE,
  PAGE_CONTRACT_TRANSACTION_INIT,
  PAGE_CONTRACT_TRANSACTION_UPDATE,
  PAGE_CONTRACT_TRANSACTION_TOTAL_UPDATE,
  PAGE_CONTRACT_TRANSACTION_LIMIT_UPDATE,
  PAGE_CONTRACT_TRANSACTION_START_UPDATE,
} from '../actions/contractTransaction'

const initContractTransaction = []

export function contractTransactionsReducer(state = initContractTransaction, action) {
  switch (action.type) {
    case CONTRACT_TRANSACTION_UPDATE:
      return action.payload
    case CONTRACT_TRANSACTION_INIT:
      return initContractTransaction
    default:
      return state
  }
}

var initPageContractTransaction = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 10000,
  current_page: 1,
}

export function pageContractTransactionReducer(state = initPageContractTransaction, action) {
  switch (action.type) {
    case PAGE_CONTRACT_TRANSACTION_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_CONTRACT_TRANSACTION_INIT:
      return initPageContractTransaction
    case PAGE_CONTRACT_TRANSACTION_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_CONTRACT_TRANSACTION_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_CONTRACT_TRANSACTION_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
