import { SEARCH_LIST_INIT, SEARCH_LIST_UPDATE, SEARCH_LIST_REQUESTING } from '../actions/searchList'

const initSearchList = {
  data: [],
  total: 0,
  type: 0,
}

export function searchListReducer(state = initSearchList, action) {
  switch (action.type) {
    case SEARCH_LIST_INIT:
      return { ...state, type: action.type }
    case SEARCH_LIST_REQUESTING:
      return { ...state, type: action.type }
    case SEARCH_LIST_UPDATE:
      return { ...state, data: action.payload.data, total: action.payload.total, type: action.type }
    default:
      return state
  }
}
