import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const GET_DAILY_TRANSACTIONS_CHART = 'GET_DAILY_TRANSACTIONS_CHART'
export const GET_DAILY_TRANSACTIONS_CHART_SUCCESS = 'GET_DAILY_TRANSACTIONS_CHART_SUCCESS'

export const GET_CUMULATIVE_TRANSACTIONS_CHART = 'GET_CUMULATIVE_TRANSACTIONS_CHART'
export const GET_CUMULATIVE_TRANSACTIONS_CHART_SUCCESS = 'GET_CUMULATIVE_TRANSACTIONS_CHART_SUCCESS'

export const GET_STAKING_RATE_CHART = 'GET_STAKING_RATE_CHART'
export const GET_STAKING_RATE_CHART_SUCCESS = 'GET_STAKING_RATE_CHART_SUCCESS'

export function getDailyTransactionsChart() {
  return {
    type: GET_DAILY_TRANSACTIONS_CHART,
  }
}
export function getDailyTransactionsChartSuccess(data) {
  return {
    type: GET_DAILY_TRANSACTIONS_CHART_SUCCESS,
    payload: data,
  }
}

export function getCumulativeTransactionsChart() {
  return {
    type: GET_CUMULATIVE_TRANSACTIONS_CHART,
  }
}
export function getCumulativeTransactionsChartSuccess(data) {
  return {
    type: GET_CUMULATIVE_TRANSACTIONS_CHART_SUCCESS,
    payload: data,
  }
}

export function getStakingRateChart() {
  return {
    type: GET_STAKING_RATE_CHART,
  }
}
export function getStakingRateChartSuccess(data) {
  return {
    type: GET_STAKING_RATE_CHART_SUCCESS,
    payload: data,
  }
}

export function loadDailyTransactionsChart(days) {
  return (dispatch) => {
    dispatch(getDailyTransactionsChart())
    fetch(`${API_ADDR}/daily-transaction?days=${days}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getDailyTransactionsChartSuccess(res.data))
      })
      .catch((err) => {})
  }
}

export function loadCumulativeTransactionsChart(days) {
  return (dispatch) => {
    dispatch(getCumulativeTransactionsChart())
    fetch(`${API_ADDR}/cumulative-transaction?days=${days}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getCumulativeTransactionsChartSuccess(res.data))
      })
      .catch((err) => {})
  }
}

export function loadStakingRateChart(days) {
  return (dispatch) => {
    dispatch(getStakingRateChart())
    fetch(`${API_ADDR}/chart-freezing-rate?days=${days}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getStakingRateChartSuccess(res.data))
      })
      .catch((err) => {})
  }
}
