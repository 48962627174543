import { Fragment } from 'react'
import { logout } from '@/actions/login'
import MainNavigationDesktop from './MainNavigationDesktop'
import MainNavigationMobile from './MainNavigationMobile'

const MainNavigation = ({
  login,
  logOut,
  generateAccount,
  handleDecodeAddressMenuClick,
  defaultPrivateKey,
  checkUrlPriv,
  initTokenRecord,
}) => {
  return (
    <Fragment>
      <MainNavigationDesktop
        login={login}
        logOut={logOut}
        generateAccount={generateAccount}
        handleDecodeAddressMenuClick={handleDecodeAddressMenuClick}
        defaultPrivateKey={defaultPrivateKey}
        checkUrlPriv={checkUrlPriv}
        initTokenRecord={initTokenRecord}
      />

      <MainNavigationMobile
        login={login}
        logOut={logOut}
        generateAccount={generateAccount}
        handleDecodeAddressMenuClick={handleDecodeAddressMenuClick}
        initTokenRecord={initTokenRecord}
      />
    </Fragment>
  )
}

export default MainNavigation
