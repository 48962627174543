import {
  RECENT_BLOCK_UPDATE,
  RECENT_BLOCK_INIT,
  RECENT_TRAN_INIT,
  RECENT_TRAN_UPDATE,
  SYSTEM_STATE_INIT,
  SYSTEM_STATE_UPDATE,
  SEARCH_REQUESTING,
  SEARCH_SUCCESS,
  AUTOCOMPLETE_SEARCH_SUCCESS,
  AUTOCOMPLETE_SEARCH_REQUESTING,
  SEARCH_RESET,
  HOME_WEL_RECENT_PRICE_UPDATE,
  HOME_WEL_HISTORY_TRANSACTION_UPDATE,
} from '../actions/home'

export function homeBlockReducer(state = { blocks: [] }, action) {
  switch (action.type) {
    case RECENT_BLOCK_UPDATE:
      state = { ...state, blocks: action.payload }
      break
    case RECENT_BLOCK_INIT:
      state = { ...state, blocks: [] }
      break
  }
  return state
}

export function homeTransReducer(state = { trans: [] }, action) {
  switch (action.type) {
    case RECENT_TRAN_UPDATE:
      state = { ...state, trans: action.payload }
      break
    case RECENT_TRAN_INIT:
      state = { ...state, trans: [] }
      break
  }
  return state
}
const initSystemState = {
  block_height: 0,
  total_nodes: 0,
  block_num: 0,
  transaction_num: 0,
  asset_num: 0,
  status: false,
}
export function homeSystemStateReducer(state = { systemState: initSystemState }, action) {
  switch (action.type) {
    case SYSTEM_STATE_UPDATE:
      return { ...state, systemState: Object.assign(action.payload, { status: true }) }
    case SYSTEM_STATE_INIT:
      return { ...state, systemState: initSystemState }
    default:
      return state
  }
}

export function searchReducer(state = { state: '', type: 0, key: '', isComplete: false }, action) {
  switch (action.type) {
    case SEARCH_REQUESTING:
      return { state: SEARCH_REQUESTING }
    case SEARCH_SUCCESS:
      return {
        state: SEARCH_SUCCESS,
        type: action.payload.type,
        key: action.payload.key,
        result: action.payload.result,
        isComplete: action.payload.isComplete,
        total: action.payload.total,
      }

    default:
      return state
  }
}

export function autoCompleteSearchReducer(
  state = { state: '', type: 0, key: '', isComplete: false },
  action
) {
  switch (action.type) {
    case AUTOCOMPLETE_SEARCH_REQUESTING:
      return { ...state, state: AUTOCOMPLETE_SEARCH_REQUESTING }

    case AUTOCOMPLETE_SEARCH_SUCCESS:
      return {
        ...state,
        state: AUTOCOMPLETE_SEARCH_SUCCESS,
        type: action.payload.type,
        key: action.payload.key,
        autoCompleteResult: action.payload.autoCompleteResult,
        isComplete: action.payload.isComplete,
      }

    default:
      return state
  }
}

export function homeWelPriceStateReducer(state = { change: 0, usd: 0 }, action) {
  switch (action.type) {
    case HOME_WEL_RECENT_PRICE_UPDATE:
      return action.payload

    default:
      return state
  }
}

export function homeWelHistoricyReducer(state = { data: [] }, action) {
  switch (action.type) {
    case HOME_WEL_HISTORY_TRANSACTION_UPDATE:
      return action.payload?.data || []
    default:
      return state
  }
}
