import {
  GET_REWARD_REQUEST,
  GET_REWARD_SUCCESS,
  GET_REWARD_FAIL,
  WITHDRAW_REWARD_REQUEST,
  WITHDRAW_REWARD_SUCCESS,
  WITHDRAW_REWARD_FAIL,
} from '../actions/reward'

const initReward = {
  loading: false,
  amount: 0,
  message: null,
  withdraw: false,
  withdrawLoading: false,
  txid: '',
}

export function rewardReducer(state = initReward, action) {
  switch (action.type) {
    case GET_REWARD_REQUEST:
      return { ...state, loading: true, amount: 0 }
    case GET_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        amount: action.payload.amount,
      }
    case GET_REWARD_FAIL:
      return { ...state, loading: false, amount: 0 }
    case WITHDRAW_REWARD_REQUEST:
      return { ...state, withdrawLoading: true, message: null, txid: '' }
    case WITHDRAW_REWARD_SUCCESS:
      return { ...state, withdrawLoading: false, message: null, txid: action.payload.txid }
    case WITHDRAW_REWARD_FAIL:
      return { ...state, withdrawLoading: false, message: action.payload }
    default:
      return state
  }
}
