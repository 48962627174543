import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const RESOURCES_REQUEST = 'RESOURCES_REQUEST'
export const RESOURCES_SUCCESS = 'RESOURCES_SUCCESS'
export const RESOURCES_FAIL = 'RESOURCES_FAIL'

export function requestResources() {
  return {
    type: RESOURCES_REQUEST,
  }
}
export function success(payload) {
  return {
    type: RESOURCES_SUCCESS,
    payload,
  }
}
export function fail(payload) {
  return {
    type: RESOURCES_FAIL,
    payload,
  }
}

export function loadResources(params) {
  return (dispatch) => {
    dispatch(requestResources())
    fetch(`${API_ADDR}/accounts/${params.address}/delegatedresources`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        const resources = []
        res.data?.forEach((item) => {
          if (item.frozen_balance_for_bandwidth && item.frozen_balance_for_energy) {
            const objBandwidth = {
              expire_time_for_bandwidth: item.expire_time_for_bandwidth,
              frozen_balance_for_bandwidth: item.frozen_balance_for_bandwidth,
              to_address: item.to_address,
            }
            const objEnergy = {
              expire_time_for_energy: item.expire_time_for_energy,
              frozen_balance_for_energy: item.frozen_balance_for_energy,
              to_address: item.to_address,
            }
            resources.push(objBandwidth, objEnergy)
          } else {
            resources.push(item)
          }
        })
        resources.sort(
          (a, b) =>
            (b.expire_time_for_bandwidth || b.expire_time_for_energy) -
            (a.expire_time_for_bandwidth || a.expire_time_for_energy)
        )
        const rb = resources.filter((item) => item.frozen_balance_for_bandwidth)
        const re = resources.filter((item) => item.frozen_balance_for_energy)
        dispatch(success(rb.concat(re)))
      })
      .catch((err) => console.log(err))
  }
}
