import fetch from 'cross-fetch'
import Oidc from 'oidc-client'

let configOidc = {
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  client_id: process.env.REACT_APP_SSO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  response_type: process.env.REACT_APP_SSO_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SSO_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_SSO_POST_LOGOUT_REDIRECT_URI,
}

export const oidcUserManager = new Oidc.UserManager(configOidc)

export const doLogin = async () => {
  await oidcUserManager.signinRedirect()
}

export const getAccessToken = async () => {
  await oidcUserManager.getUser().then(function (user) {})
}

export const signoutRedirect = async () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}
