import { lazy } from 'react'

export default [
  {
    path: '/home',
    component: lazy(() => import('../pages/home')),
  },
  {
    path: '/login',
    component: lazy(() => import('../pages/login')),
  },
  {
    path: '/signup',
    component: lazy(() => import('../pages/signup')),
  },
  {
    path: '/activate-account',
    component: lazy(() => import('../pages/signup/activate.js')),
  },
  {
    path: '/idshare-login-calback',
    component: lazy(() => import('../pages/idshareLoginCallback')),
  },
  {
    path: '/witness',
    component: lazy(() => import('../pages/witnesses/WitnessList')),
  },
  {
    path: '/blocks',
    component: lazy(() => import('../pages/blocks/BlockList')),
  },
  {
    path: '/block/:id',
    component: lazy(() => import('../pages/blocks/blockDetail')),
  },
  {
    path: '/nodes',
    component: lazy(() => import('../pages/nodes')),
  },
  {
    path: '/transactions',
    component: lazy(() => import('../pages/transactions/TransactionList')),
  },
  {
    path: '/transaction/:id',
    component: lazy(() => import('../pages/transactions/transactionDetail')),
  },
  {
    path: '/accounts/:id',
    component: lazy(() => import('../pages/addresses/addressDetail')),
  },
  {
    path: '/accounts',
    component: lazy(() => import('../pages/addresses')),
  },
  {
    path: '/tokens',
    component: lazy(() => import('../pages/token/TokenList')),
  },
  {
    path: '/token/:tokenId',
    component: lazy(() => import('../pages/token/TokenWRC10')),
  },
  {
    path: '/token(\\d{2,3})/:tokenId',
    component: lazy(() => import('../pages/token/TokenRC20')),
  },
  {
    path: '/contracts',
    component: lazy(() => import('../pages/contracts')),
  },
  {
    path: '/contract/:id',
    component: lazy(() => import('../pages/contracts/detail')),
  },
  {
    path: '/token-record/create',
    component: lazy(() => import('../pages/tokenRecord')),
  },
  {
    path: '/update/token20/:id',
    component: lazy(() => import('../pages/tokenRecord')),
  },
  {
    path: '/update/token10/:id',
    component: lazy(() => import('../pages/tokenRecord')),
  },
  {
    path: '/update/token721/:id',
    component: lazy(() => import('../pages/tokenRecord')),
  },
  {
    path: '/representatives',
    component: lazy(() => import('../pages/representatives')),
  },
  {
    path: '/votes',
    component: lazy(() => import('../pages/votes')),
  },
  {
    path: '/foundation',
    component: lazy(() => import('../pages/foundation/foundationList')),
  },
  {
    path: '/data/transactions',
    component: lazy(() => import('../pages/charts')),
  },
  {
    path: '/user/transferasset',
    component: lazy(() => import('../pages/transferAsset')),
  },
  {
    path: '/user/transferwrc20',
    component: lazy(() => import('../pages/transferWRC20')),
  },
  {
    path: '/user/issue-token-wrc10',
    component: lazy(() => import('../pages/issueTokenWRC10')),
  },
  {
    path: '/user/issue-token-wrc10',
    component: lazy(() => import('../pages/issueTokenWRC10')),
  },
  {
    path: '/user/freeze-balance',
    component: lazy(() => import('../pages/freezeBalance')),
  },
  {
    path: '/user/deploycontract',
    component: lazy(() => import('../pages/deployContract')),
  },
  {
    path: '/account/:id',
    component: lazy(() => import('../pages/account')),
  },
  {
    path: '/wallet',
    component: lazy(() => import('../pages/wallet')),
  },
  {
    path: '/prikey-management',
    component: lazy(() => import('../pages/prikeyManagement')),
  },
  {
    path: '/resetpassword',
    component: lazy(() => import('../pages/resetPassword')),
  },
  {
    path: '/newpassword',
    component: lazy(() => import('../pages/resetPassword/newPassword')),
  },
  {
    path: '/search/:id',
    component: lazy(() => import('../pages/search/searchResults')),
  },
  {
    path: '/searcherror',
    component: lazy(() => import('../pages/search/searchError')),
  },
  {
    path: '/searchmore',
    component: lazy(() => import('../pages/search/searchError')),
  },
  {
    path: '/tokenholder',
    component: lazy(() => import('../pages/tokenHolder')),
  },
  {
    path: '/notfound',
    component: lazy(() => import('../pages/notFound')),
  },
]
