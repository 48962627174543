import { fetch } from 'cross-fetch'
import { API_ADDR } from '../config/config'
export default class ConfigKey {
  static async getkey() {
    try {
      const res = await fetch(`${API_ADDR}/get/key`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      })
        .then((result) => result.json())
        .then((result) => {
          return result.data
        })
        .catch((err) => {
          return {
            REACT_APP_API_ADDRESS_ADMIN: '',
            REACT_APP_API_S3_UPLOAD_FEE: 0,
            REACT_APP_API_CREATE_RC10_FEE: 0,
            REACT_APP_API_CREATE_RC20_FEE: 0,
            REACT_APP_API_CREATE_RC721_FEE: 0,
            REACT_APP_API_UPDATE_RC10_FEE: 0,
            REACT_APP_API_UPDATE_RC20_FEE: 0,
            REACT_APP_API_UPDATE_RC721_FEE: 0,
          }
        })
      return res
    } catch (e) {
      return {
        REACT_APP_API_ADDRESS_ADMIN: '',
        REACT_APP_API_S3_UPLOAD_FEE: 0,
        REACT_APP_API_CREATE_RC10_FEE: 0,
        REACT_APP_API_CREATE_RC20_FEE: 0,
        REACT_APP_API_CREATE_RC721_FEE: 0,
        REACT_APP_API_UPDATE_RC10_FEE: 0,
        REACT_APP_API_UPDATE_RC20_FEE: 0,
        REACT_APP_API_UPDATE_RC721_FEE: 0,
      }
    }
  }
}
