import {
  ADDRESS_TRANSACTION_INIT,
  ADDRESS_TRANSACTION_UPDATE,
  PAGE_ADDRESS_TRANSACTION_INIT,
  PAGE_ADDRESS_TRANSACTION_UPDATE,
  PAGE_ADDRESS_TRANSACTION_TOTAL_UPDATE,
  PAGE_ADDRESS_TRANSACTION_LIMIT_UPDATE,
  PAGE_ADDRESS_TRANSACTION_START_UPDATE,
} from '../actions/addressTransaction'

const initAddressTransaction = []

export function addressTransactionsReducer(state = initAddressTransaction, action) {
  switch (action.type) {
    case ADDRESS_TRANSACTION_UPDATE:
      return action.payload
    case ADDRESS_TRANSACTION_INIT:
      return initAddressTransaction
    default:
      return state
  }
}

var initPageAddressTransaction = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 10000,
  current_page: 1,
}

export function pageAddressTransactionReducer(state = initPageAddressTransaction, action) {
  switch (action.type) {
    case PAGE_ADDRESS_TRANSACTION_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_ADDRESS_TRANSACTION_INIT:
      return initPageAddressTransaction
    case PAGE_ADDRESS_TRANSACTION_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_ADDRESS_TRANSACTION_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_ADDRESS_TRANSACTION_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
