import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import { decode58Check, getBase58CheckAddress } from '@tronscan/client/src/utils/crypto'
import { byteArray2hexStr } from '@tronscan/client/src/utils/bytes'
import { toChecksumAddress } from 'ethereum-checksum-address'
import { hexStr2byteArray } from '@tronscan/client/src/lib/code'
import { CONTRACT_ADDR } from '../config/config'

TimeAgo.addLocale(en)

export function toDateTime(timestamp) {
  timestamp = timestamp <= Date.now() ? timestamp : timestamp / Math.pow(10, 6)
  if (timestamp < 1627776000000) {
    return '--'
  }
  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(timestamp)
}

export function toDateTimeEndTime(timestamp) {
  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(timestamp)
}

export function toTimeAgo(timestamp) {
  timestamp = timestamp <= Date.now() ? timestamp : timestamp / Math.pow(10, 6)
  return <ReactTimeAgo date={timestamp} locale="en-US" />
}

export function decimalFormat(value, precision = 4) {
  return value
}

export function currencyFormat(value) {
  // return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return Intl.NumberFormat().format(value)
}

export function addressToHex(addr) {
  try {
    return toChecksumAddress('0x' + byteArray2hexStr(decode58Check(addr)).substring(2))
  } catch (e) {
    console.error(e)
    return null
  }
}

export function hexToAddress(hexStr) {
  const bytes = hexStr2byteArray(hexStr)
  return getBase58CheckAddress(bytes)
}

export function mapTransactionType(value) {
  return value.replace(/([A-Z])/g, ' $1').trim()
}

export function formatNumber(num) {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function multipleExist(arr, values) {
  return values.every((value) => {
    return arr?.includes(value)
  })
}

export function isWRC20Transfer(method, abi) {
  const values = [
    'name',
    'totalSupply',
    'decimals',
    'balanceOf',
    'symbol',
    'allowance',
    'approve',
    'transferFrom',
    'increaseAllowance',
    'decreaseAllowance',
    'transfer',
  ]
  const arr = abi?.map((item) => item.name)
  if (method === 'transfer (address recipient, uint256 amount)' && multipleExist(arr, values)) {
    return true
  }
  return false
}

export function isSwap(contractAddress, method) {
  const swapType = [
    'swapExactETHForTokens',
    'swapETHForExactTokens',
    'swapExactTokensForETH',
    'swapTokensForExactETH',
    'swapExactTokensForTokens',
    'swapTokensForExactTokens',
  ]

  if (CONTRACT_ADDR.includes(contractAddress) && swapType.includes(method)) {
    return true
  }
  return false
}

export function handleTokenTransfer(eventLogs, decode, contract) {
  const decodeTo = decode?.parameter?.find((item) => item.name === 'to')
  const eventLogsFilter = eventLogs?.filter((item) => item.eventName !== 'Approval')

  switch (decode?.method) {
    case 'swapExactETHForTokens':
    case 'swapETHForExactTokens':
      return eventLogsFilter?.map((item, i) => {
        const contractFind = contract?.find(
          (itemContract) => itemContract.contractAddress === item.contractAddress
        )

        return {
          from:
            item.topicMap.src || item.topicMap.from || item.topicMap.dst || item.contractAddress,
          to: i === 0 ? item.contractAddress : item.topicMap.dst || item.topicMap.to,
          value: item.dataMap.wad || item.dataMap.value,
          decodeTo: decodeTo?.data,
          decimal: contractFind?.decimal,
          symbol: i === 0 ? 'WELUPS' : contractFind?.symbol,
        }
      })
    case 'swapExactTokensForETH':
    case 'swapTokensForExactETH':
      return eventLogsFilter?.map((item, i) => {
        const contractFind = contract?.find(
          (itemContract) => itemContract.contractAddress === item.contractAddress
        )

        return {
          from: item.topicMap.src || item.topicMap.from,
          to: item.topicMap.dst || item.topicMap.to || decodeTo?.data,
          value: item.dataMap.wad || item.dataMap.value,
          decodeTo: decodeTo?.data,
          decimal: contractFind?.decimal,
          symbol: i === eventLogsFilter.length - 1 ? 'WELUPS' : contractFind?.symbol,
        }
      })
    case 'swapExactTokensForTokens':
    case 'swapTokensForExactTokens':
      return eventLogsFilter?.map((item, i) => {
        const contractFind = contract?.find(
          (itemContract) => itemContract.contractAddress === item.contractAddress
        )

        return {
          from: item.topicMap.from || item.topicMap.src,
          to: item.topicMap.to || item.topicMap.dst,
          value: item.dataMap.wad || item.dataMap.value,
          decodeTo: decodeTo?.data,
          decimal: contractFind?.decimal,
          symbol: contractFind?.symbol,
        }
      })
    default:
      return ''
  }
}
