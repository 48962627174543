import { URL_PRIVATE, LOAD_URL_PRIVATE } from '../actions/checkUrlPriv'

const defaultValueState = false

export function checkUrlPrivReducer(state = [], action) {
  switch (action.type) {
    case URL_PRIVATE:
      state = defaultValueState
      break
    case LOAD_URL_PRIVATE:
      state = action.payload
      break
  }
  return state
}
