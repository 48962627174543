import fetch from 'cross-fetch'
import { notification } from 'antd'

import { API_ADDR } from '../config/config'
export const FIND_TOKEN_HOLDERS_INIT = 'FIND_TOKEN_HOLDERS_INIT'
export const FIND_TOKEN_HOLDERS_UPDATE = 'FIND_TOKEN_HOLDERS_UPDATE'

export function initFindTokenHolders() {
  return {
    type: FIND_TOKEN_HOLDERS_INIT,
  }
}
export function updateFindTokenHolders(payload) {
  return {
    type: FIND_TOKEN_HOLDERS_UPDATE,
    payload,
  }
}

export function loadFindTokenHolders(address, search) {
  return (dispatch) => {
    fetch(`${API_ADDR}/tokenholder/owner/${address}/${search}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (Object.keys(res.data).length !== 0) {
          dispatch(updateFindTokenHolders([res.data]))
        } else {
          notification.warning({
            message: 'Warning!',
            description: 'Search not found',
          })
        }
      })
      .catch((err) => {})
  }
}
