import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const TOKEN_HOLDERS_INIT = 'TOKEN_HOLDERS_INIT'
export const TOKEN_HOLDERS_UPDATE = 'TOKEN_HOLDERS_UPDATE'

export function initTokenHolders() {
  return {
    type: TOKEN_HOLDERS_INIT,
  }
}
export function updateTokenHolders(tokens) {
  return {
    type: TOKEN_HOLDERS_UPDATE,
    payload: tokens,
  }
}

export function loadTokenHolders(address, page, limit) {
  return (dispatch) => {
    dispatch(initTokenHolders())
    fetch(
      `${API_ADDR}/tokenholder${address ? `/owners/${address}` : ''}?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageTokenHoldersTotal(res.data.total))
        dispatch(updateTokenHolders(res.data.result))
      })
      .catch((err) => {})
  }
}

export const PAGE_TOKEN_HOLDERS_INIT = 'PAGE_TOKEN_HOLDERS_INIT'
export const PAGE_TOKEN_HOLDERS_UPDATE = 'PAGE_TOKEN_HOLDERS_UPDATE'
export const PAGE_TOKEN_HOLDERS_TOTAL_UPDATE = 'PAGE_TOKEN_HOLDERS_TOTAL_UPDATE'
export const PAGE_TOKEN_HOLDERS_LIMIT_UPDATE = 'PAGE_TOKEN_HOLDERS_LIMIT_UPDATE'
export const PAGE_TOKEN_HOLDERS_START_UPDATE = 'PAGE_TOKEN_HOLDERS_START_UPDATE'

export function initPageTokenHolders() {
  return {
    type: PAGE_TOKEN_HOLDERS_INIT,
  }
}
export function updatePageTokenHolders(page) {
  return {
    type: PAGE_TOKEN_HOLDERS_UPDATE,
    payload: page,
  }
}

export function updatePageTokenHoldersTotal(total) {
  return {
    type: PAGE_TOKEN_HOLDERS_TOTAL_UPDATE,
    payload: total,
  }
}

export function updatePageTokenHoldersLimit(limit) {
  return {
    type: PAGE_TOKEN_HOLDERS_LIMIT_UPDATE,
    payload: limit,
  }
}

export function updatePageTokenHoldersStart(start_page) {
  return {
    type: PAGE_TOKEN_HOLDERS_START_UPDATE,
    payload: start_page,
  }
}
