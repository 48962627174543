import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const NODES_INIT = 'NODES_INIT'
export const NODES_UPDATE = 'NODES_UPDATE'

export function initNodes() {
  return {
    type: NODES_INIT,
  }
}
export function updateNodes(nodes) {
  var nodeList = []
  for (var i = 0; i < nodes.length; i++) {
    var a = {
      host: nodes[i].slice(0, nodes[i].indexOf(':')),
      port: nodes[i].slice(nodes[i].indexOf(':') + 1),
    }
    nodeList.push(a)
  }
  return {
    type: NODES_UPDATE,
    payload: nodeList,
  }
}

export function loadNodes() {
  return (dispatch) => {
    dispatch(initNodes())
    fetch(`${API_ADDR}/node/lists`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateNodes(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
