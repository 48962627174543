import {
  UNFREEZE_BALANCE_INIT,
  UNFREEZE_BALANCE_REQUEST,
  UNFREEZE_BALANCE_SUCCESS,
  UNFREEZE_BALANCE_FAIL,
} from '../actions/unfreezeBalance'

const initVote = {
  loading: false,
  unfreezeBalance: null,
  message: null,
}

export function unfreezeBalanceReducer(state = initVote, action) {
  switch (action.type) {
    case UNFREEZE_BALANCE_INIT:
      return initVote
    case UNFREEZE_BALANCE_REQUEST:
      return { ...state, loading: true, unfreezeBalance: null, message: null }
    case UNFREEZE_BALANCE_SUCCESS:
      return { ...state, loading: false, unfreezeBalance: action.payload }
    case UNFREEZE_BALANCE_FAIL:
      return { ...state, loading: false, unfreezeBalance: null, message: action.payload }
    default:
      return state
  }
}
