export const DEFAULT_PRIVATE_KEY_DEFAULT = 'DEFAULT_PRIVATE_KEY_DEFAULT'
export const DEFAULT_PRIVATE_KEY_LOAD = 'DEFAULT_PRIVATE_KEY_LOAD'

export function defaultPrivateKey() {
  return {
    type: DEFAULT_PRIVATE_KEY_DEFAULT,
  }
}

export function loadDefaultPrivateKey(address, priv) {
  return {
    type: DEFAULT_PRIVATE_KEY_LOAD,
    payload: {
      address: address,
      priv: priv,
    },
  }
}

export function setDefaultPrivateKey(email, address, priv) {
  return (dispatch) => {
    dispatch(defaultPrivateKey())
    localStorage.setItem(
      email + 'defaultKey',
      JSON.stringify({
        address: address,
        priv: priv,
      })
    )
    dispatch(loadDefaultPrivateKey(address, priv))
  }
}

export function deleteDefaultPrivateKey(email) {
  return (dispatch) => {
    var check = false
    var privs = []
    if (localStorage.getItem(email)) {
      privs = JSON.parse(localStorage.getItem(email))
    }

    var priv = ''

    if (localStorage.getItem(email + 'defaultKey')) {
      priv = JSON.parse(localStorage.getItem(email + 'defaultKey')).priv
    }

    if (privs.length != 0) {
      privs.forEach((elem) => {
        if (elem.prikey === priv) {
          check = true
        }
      })
      if (!check) {
        localStorage.setItem(
          email + 'defaultKey',
          JSON.stringify({
            address: privs[0].address,
            priv: privs[0].prikey,
          })
        )
        dispatch(loadDefaultPrivateKey(privs[0].address, privs[0].prikey))
      }
    } else {
      localStorage.setItem(
        email + 'defaultKey',
        JSON.stringify({
          address: undefined,
          priv: undefined,
        })
      )
      dispatch(loadDefaultPrivateKey(undefined, undefined))
    }
  }
}

export function getDefaultPrivateKey(email) {
  return (dispatch) => {
    dispatch(defaultPrivateKey())
    var temp = localStorage.getItem(email + 'defaultKey')
    if (temp) {
      var result = JSON.parse(temp)
      dispatch(loadDefaultPrivateKey(result.address, result.priv))
    }
  }
}
