import { notification } from 'antd'
import { API_ADDR } from '../config/config'
import { GetPrivateKeyByAddress } from '../common/validate'

export const FREEZE_BALANCE_NONE = 'FREEZE_BALANCE_NONE'
export const FREEZE_BALANCE_REQUESTING = 'FREEZE_BALANCE_REQUESTING'
export const FREEZE_BALANCE_SUCCESS = 'FREEZE_BALANCE_SUCCESS'
export const FREEZE_BALANCE_FAIL = 'FREEZE_BALANCE_FAIL'
export function reset() {
  return {
    type: FREEZE_BALANCE_NONE,
  }
}
export function request() {
  return {
    type: FREEZE_BALANCE_REQUESTING,
  }
}
export function success(tranID) {
  return {
    type: FREEZE_BALANCE_SUCCESS,
    payload: {
      tranID: tranID,
    },
  }
}
export function fail(message) {
  return {
    type: FREEZE_BALANCE_FAIL,
    payload: message,
  }
}

export function freezeBalance(
  frozen_balance,
  frozen_duration,
  resource,
  receiver_address,
  privs,
  owner_address
) {
  return async (dispatch) => {
    dispatch(request())
    var private_key = GetPrivateKeyByAddress(privs, owner_address)

    fetch(`${API_ADDR}/freezebalance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        owner_address: owner_address,
        frozen_balance: String(frozen_balance * Math.pow(10, 6)),
        frozen_duration: frozen_duration,
        resource: resource,
        receiver_address: receiver_address,
        private_key: private_key,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'fail') {
          dispatch(fail(res.message))
          notification.error({
            message: res.message,
            description: `Failed`,
          })
        } else if (res.status === 'success') {
          if (res.data.result === true) {
            dispatch(success(res.data.txid))
            notification.success({
              message: 'TxID: ' + res.data.txid,
              description: `Succeeded`,
            })
          } else {
            dispatch(fail('Freeze balance failed'))
            notification.error({
              message: 'Transfer ' + res.data.txid,
              description: `Failed`,
            })
          }
        }
      })
      .catch((err) => {
        dispatch(fail(err))
        notification.error({
          message: err,
          description: `Failed`,
        })
      })
  }
}
