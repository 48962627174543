import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'
export const SEARCH_LIST_REQUESTING = 'SEARCH_LIST_REQUESTING'
export const SEARCH_LIST_INIT = 'SEARCH_LIST_INIT'
export const SEARCH_LIST_UPDATE = 'SEARCH_LIST_UPDATE'

export function requestingSearchList() {
  return {
    type: SEARCH_LIST_REQUESTING,
  }
}

export function initSearchList() {
  return {
    type: SEARCH_LIST_INIT,
  }
}
export function updateSearchList(data, total) {
  return {
    type: SEARCH_LIST_UPDATE,
    payload: {
      data: data,
      total: total,
    },
  }
}

export function loadSearchList(key, offset, limit) {
  return (dispatch) => {
    dispatch(requestingSearchList())
    fetch(`${API_ADDR}/contract/${key}?offset=${offset}&limit=${limit}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateSearchList(res.data.data, res.data.total))
      })
      .catch((err) => {})
  }
}
