import { API_ADDR } from '../config/config'

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}

export async function GetTransaction(transactionID, timeFetch) {
  let result = ''
  for (let countTimeFetchData = 0; countTimeFetchData < timeFetch; countTimeFetchData++) {
    const { message, data } = await fetch(`${API_ADDR}/transactions/${transactionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          String(res.status).toUpperCase() == 'SUCESS' ||
          String(res.status).toUpperCase() == 'SUCCESS'
        ) {
          return {
            status: true,
            message: 'SUCCESS',
            data: res.data,
          }
        } else {
          return {
            status: false,
            message: res.message,
            data: {},
          }
        }
      })
      .catch((err) => {
        return {
          status: false,
          message: 'Wallet has insufficient balance',
          data: {},
        }
      })
    if (message === 'SUCCESS') {
      return {
        status: true,
        data: data,
        message: '',
      }
    }
    result = message
    await timeout(3000)
  }

  if (result !== 'SUCCESS') {
    return {
      status: false,
      message: result,
      dataa: {},
    }
  }
}
