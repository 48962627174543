import {
  ADDRESS_TRANSFER_INIT,
  ADDRESS_TRANSFER_UPDATE,
  PAGE_ADDRESS_TRANSFER_INIT,
  PAGE_ADDRESS_TRANSFER_UPDATE,
  PAGE_ADDRESS_TRANSFER_TOTAL_UPDATE,
  PAGE_ADDRESS_TRANSFER_LIMIT_UPDATE,
  PAGE_ADDRESS_TRANSFER_START_UPDATE,
} from '../actions/addressTransfer'

const initAddressTransfer = []

export function addressTransfersReducer(state = initAddressTransfer, action) {
  switch (action.type) {
    case ADDRESS_TRANSFER_UPDATE:
      return action.payload
    case ADDRESS_TRANSFER_INIT:
      return initAddressTransfer
    default:
      return state
  }
}

var initPageAddressTransfer = {
  start_page: 1,
  start_item: 0,
  page_limit: 20,
  total_items: 10000,
  current_page: 1,
}

export function pageAddressTransferReducer(state = initPageAddressTransfer, action) {
  switch (action.type) {
    case PAGE_ADDRESS_TRANSFER_UPDATE:
      state.current_page = action.payload
      state.start_item -= (state.current_page - state.start_page) * state.page_limit
      state.start_page = action.payload
      return state
    case PAGE_ADDRESS_TRANSFER_INIT:
      return initPageAddressTransfer
    case PAGE_ADDRESS_TRANSFER_TOTAL_UPDATE:
      state.total_items = action.payload
      return state
    case PAGE_ADDRESS_TRANSFER_LIMIT_UPDATE:
      state.start_item -= (action.payload - state.page_limit) * (state.current_page - 1)
      state.page_limit = action.payload
      return state
    case PAGE_ADDRESS_TRANSFER_START_UPDATE:
      state.start_item = action.payload
      return state
    default:
      return state
  }
}
