import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const UNFREEZE_BALANCE_INIT = 'UNFREEZE_BALANCE_INIT'
export const UNFREEZE_BALANCE_REQUEST = 'UNFREEZE_BALANCE_REQUEST'
export const UNFREEZE_BALANCE_SUCCESS = 'UNFREEZE_BALANCE_SUCCESS'
export const UNFREEZE_BALANCE_FAIL = 'UNFREEZE_BALANCE_FAIL'

export function init() {
  return {
    type: UNFREEZE_BALANCE_INIT,
  }
}
export function request() {
  return {
    type: UNFREEZE_BALANCE_REQUEST,
  }
}
export function success(payload) {
  return {
    type: UNFREEZE_BALANCE_SUCCESS,
    payload,
  }
}
export function fail(payload) {
  return {
    type: UNFREEZE_BALANCE_FAIL,
    payload,
  }
}

export function loadUnfreezeBalance(payload) {
  return (dispatch) => {
    dispatch(request())
    fetch(`${API_ADDR}/unfreezebalance`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        res.status === 'success' ? dispatch(success(res.data)) : dispatch(fail(res.message))
      })
      .catch((err) => console.log(err))
  }
}
