import fetch from 'cross-fetch'

import { API_ADDR } from '../config/config'

export function loginWithIDShare(data) {
  fetch(`${API_ADDR}/useridshare`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      access_token: data.access_token,
      expires_at: data.expires_at,
      id_token: data.id_token,
      refresh_token: data.refresh_token,
      session_state: data.session_state,
      state: data.state === undefined ? '' : data.state,
      token_type: data.token_type,
      profile: {
        s_hash: data.profile.s_hash,
        program: data.profile.program,
        auth_time: data.profile.auth_time,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('user', data.profile.email)
      window.location.replace('/home')
    })
    .catch((err) => {})
}
