import {
  TRANSACTION_DETAIL_INIT,
  TRANSACTION_DETAIL_UDPATE,
  DECODE_INIT,
  DECODE_UPDATE,
  EVENT_LOGS_INIT,
  EVENT_LOGS_UPDATE,
  DECIMAL_CONTRACT_INIT,
  DECIMAL_CONTRACT_UPDATE,
} from '../actions/transaction'

const initTran = {
  hash: '',
  blockNum: 0,
  contractAddr: '',
  result: '',
  timestamp: 0,
  numOfBlocks: 0,
  confirmed: '',
  contract: {
    type: '',
    parameter: {
      type_url: '',
      value: '',
      raw: {},
    },
  },
  fee_limit: 0,
  loading: true,
}
export function transactionReducer(state = initTran, action) {
  switch (action.type) {
    case TRANSACTION_DETAIL_UDPATE:
      return action.payload
    case TRANSACTION_DETAIL_INIT:
      return initTran
    default:
      return state
  }
}

const initDecode = {}
export function decodeReducer(state = initDecode, action) {
  switch (action.type) {
    case DECODE_INIT:
      return initDecode
    case DECODE_UPDATE:
      return action.payload
    default:
      return state
  }
}

const initEventLogs = []
export function eventLogsReducer(state = initEventLogs, action) {
  switch (action.type) {
    case EVENT_LOGS_INIT:
      return initEventLogs
    case EVENT_LOGS_UPDATE:
      return action.payload
    default:
      return state
  }
}

const initDecimalContract = []
export function decimalContractReducer(state = initDecimalContract, action) {
  switch (action.type) {
    case DECIMAL_CONTRACT_INIT:
      return initDecimalContract
    case DECIMAL_CONTRACT_UPDATE:
      return action.payload
    default:
      return state
  }
}
