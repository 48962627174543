import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const GET_VOTE_REQUEST = 'GET_VOTE_REQUEST'
export const GET_VOTE_SUCCESS = 'GET_VOTE_SUCCESS'
export const GET_VOTE_FAIL = 'GET_VOTE_FAIL'

export function getVoteRequest() {
  return {
    type: GET_VOTE_REQUEST,
  }
}
export function getVoteSuccess(payload) {
  return {
    type: GET_VOTE_SUCCESS,
    payload,
  }
}
export function getVoteFail() {
  return {
    type: GET_VOTE_FAIL,
  }
}

export function getVote() {
  return (dispatch) => {
    dispatch(getVoteRequest())
    fetch(`${API_ADDR}/vote/witness`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getVoteSuccess(res.data))
      })
      .catch(() => dispatch(getVoteFail()))
  }
}

export const UPDATE_VOTE_REQUEST = 'UPDATE_VOTE_REQUEST'
export const UPDATE_VOTE_SUCCESS = 'UPDATE_VOTE_SUCCESS'
export const UPDATE_VOTE_FAIL = 'UPDATE_VOTE_FAIL'

export function updateVoteRequest() {
  return {
    type: UPDATE_VOTE_REQUEST,
  }
}
export function updateVoteSuccess(payload) {
  return {
    type: UPDATE_VOTE_SUCCESS,
    payload,
  }
}
export function updateVoteFail(payload) {
  return {
    type: UPDATE_VOTE_FAIL,
    payload,
  }
}

export function updateVote(payload) {
  return (dispatch) => {
    dispatch(updateVoteRequest())
    fetch(`${API_ADDR}/votewitness`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        res.status === 'success'
          ? dispatch(updateVoteSuccess(res.data))
          : dispatch(updateVoteFail(res.message))
      })
      .catch((err) => console.log(err))
  }
}
