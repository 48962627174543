import fetch from 'cross-fetch'
import { notification } from 'antd'
import { API_ADDR, LBANK_WS_ADDR, WS_ADDR } from '../config/config'
export const RECENT_BLOCK_UPDATE = 'RECENT_BLOCK_UPDATE'
export const RECENT_BLOCK_INIT = 'RECENT_BLOCK_INIT'

export function initRecentBlock() {
  return {
    type: RECENT_BLOCK_INIT,
  }
}
export function updateRecentBlock(blocks) {
  return {
    type: RECENT_BLOCK_UPDATE,
    payload: blocks,
  }
}

export function loadRecentBlocks() {
  return (dispatch) => {
    dispatch(initRecentBlock())
    fetch(`${API_ADDR}/blocks/recent`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateRecentBlock(res.data))
      })
      .then(() => {
        let ws = new WebSocket(`${WS_ADDR}/ws/blocks/recent`)
        ws.onerror = function (err) {}
        ws.onclose = function (event) {}
        ws.onmessage = (e) => {
          let blocks = JSON.parse(e.data)
          dispatch(updateRecentBlock(blocks))
        }
      })
      .catch((err) => {})
  }
}

export const RECENT_TRAN_UPDATE = 'RECENT_TRAN_UPDATE'
export const RECENT_TRAN_INIT = 'RECENT_TRAN_INIT'

export function updateRecentTrans(trans) {
  return {
    type: RECENT_TRAN_UPDATE,
    payload: trans,
  }
}

export function initRecentTrans() {
  return {
    type: RECENT_TRAN_INIT,
  }
}

export function loadRecentTrans() {
  return (dispatch) => {
    dispatch(initRecentTrans())
    fetch(`${API_ADDR}/transactions/recent`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateRecentTrans(res.data))
      })
      .then(() => {
        let ws = new WebSocket(`${WS_ADDR}/ws/transactions/recent`)
        ws.onerror = function (err) {}
        ws.onclose = function (event) {}
        ws.onmessage = (e) => {
          let trans = JSON.parse(e.data)
          dispatch(updateRecentTrans(trans))
        }
      })
      .catch((err) => {})
  }
}

export const SYSTEM_STATE_INIT = 'SYSTEM_STATE_INIT'
export const SYSTEM_STATE_UPDATE = 'SYSTEM_STATE_UPDATE'

export function initSystemState() {
  return {
    type: SYSTEM_STATE_INIT,
  }
}

export function updateSystemState(systemState) {
  return {
    type: SYSTEM_STATE_UPDATE,
    payload: systemState,
  }
}

export function loadSystemState() {
  return (dispatch) => {
    dispatch(initSystemState())
    fetch(`${API_ADDR}/system/state`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateSystemState(res.data))
      })
      .then(() => {
        let ws = new WebSocket(`${WS_ADDR}/ws/system/state`)
        ws.onerror = function (err) {}
        ws.onclose = function (event) {}
        ws.onmessage = (e) => {
          let systemState = JSON.parse(e.data)
          dispatch(updateSystemState(systemState))
        }
      })
      .catch((err) => {})
  }
}

export const SEARCH_REQUESTING = 'SEARCH_REQUESTING'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_RESET = 'SEARCH_RESET'

export const AUTOCOMPLETE_SEARCH_REQUESTING = 'AUTOCOMPLETE_SEARCH_REQUESTING'
export const AUTOCOMPLETE_SEARCH_SUCCESS = 'AUTOCOMPLETE_SEARCH_SUCCESS'

export function requestSearch() {
  return {
    type: SEARCH_REQUESTING,
  }
}

export function requestAutoCompleteSearch() {
  return {
    type: AUTOCOMPLETE_SEARCH_REQUESTING,
  }
}

export function resetSearch() {
  return {
    type: SEARCH_RESET,
  }
}

export function updateSearchResult(objType, key, result, total) {
  return {
    type: SEARCH_SUCCESS,
    payload: {
      type: objType,
      key: key,
      result: result,
      total: total,
      // isComplete: isComplete,
    },
  }
}
export function updateAutoCompleteSearchResult(objType, key, result, isComplete) {
  return {
    type: AUTOCOMPLETE_SEARCH_SUCCESS,
    payload: {
      type: objType,
      key: key,
      autoCompleteResult: result,
      isComplete: isComplete,
    },
  }
}

export function search(key) {
  return (dispatch) => {
    dispatch(requestSearch())
    fetch(`${API_ADDR}/search/${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateSearchResult(res.data.type, key))
      })
      .catch((err) => {
        dispatch(updateSearchResult({ type: 0 }, key))
      })
  }
}

export function mainSearch(key, page = 1, limit = 20) {
  return (dispatch) => {
    dispatch(requestSearch())
    fetch(`${API_ADDR}/search-main?term=${key}&page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateSearchResult(res.data.type, key, res.data.search_result, res.data.total))
      })
      .catch((err) => {
        dispatch(updateSearchResult({ type: 0 }, key))
      })
  }
}

export function filterSearch(key, type) {
  return (dispatch) => {
    dispatch(requestSearch())
    fetch(`${API_ADDR}/search-type?term=${key}&type=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateSearchResult(res.data.type, key, res.data.search_result))
      })
      .catch((err) => {
        dispatch(updateSearchResult({ type: 0 }, key))
      })
  }
}

export function autoCompleteSearch(key, isComplete, page = 1, limit = 20) {
  return (dispatch) => {
    dispatch(requestAutoCompleteSearch())
    fetch(`${API_ADDR}/search-main?term=${key}&page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          updateAutoCompleteSearchResult(res.data.type, key, res.data.search_result, isComplete)
        )
      })
      .catch((err) => {
        dispatch(updateAutoCompleteSearchResult({ type: 0 }, key))
      })
  }
}

export function filterAutoCompleteSearch(key, type) {
  return (dispatch) => {
    dispatch(requestAutoCompleteSearch())
    fetch(`${API_ADDR}/search-type?term=${key}&type=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateAutoCompleteSearchResult(res.data.type, key, res.data.search_result))
      })
      .catch((err) => {
        dispatch(updateAutoCompleteSearchResult({ type: 0 }, key))
      })
  }
}

const webSockets = {}
export const HOME_WEL_RECENT_PRICE_UPDATE = 'HOME_WEL_RECENT_PRICE_UPDATE'
export function fetchWelPrice() {
  return (dispatch) => {
    getWelupsPriceFromLBank(dispatch)
    getWelupsPriceFromCoinMarketCap(dispatch)
  }
}

function getWelupsPriceFromLBank(dispatch) {
  const ws = new WebSocket(LBANK_WS_ADDR)
  ws.onerror = function (err) {
    ws.close()
    webSockets.lbank = null
  }
  ws.onclose = function (event) {
    webSockets.lbank = null
    if (!webSockets.coinMarketCap) {
      getWelupsPriceFromXT(dispatch)
    }
  }
  ws.onmessage = function (e) {
    const data = JSON.parse(e.data)
    if (data.type === 'tick') {
      dispatch(updateWelPrice(data))
      if (webSockets.coinMarketCap) {
        ws.close()
      }
    } else if (data.action === 'ping') {
      setTimeout(() => {
        ws.send(
          JSON.stringify({
            pong: data.ping,
            action: 'pong',
          })
        )
      }, 1000)
    }
  }
  ws.onopen = function () {
    webSockets.lbank = ws
    ws.send(
      JSON.stringify({
        action: 'subscribe',
        subscribe: 'tick',
        pair: 'welups_usdt',
      })
    )
  }
}

function getWelupsPriceFromCoinMarketCap(dispatch) {
  const ws = new WebSocket('wss://stream.coinmarketcap.com/price/latest')
  ws.onerror = function (err) {
    ws.close()
    webSockets.coinMarketCap = null
  }
  ws.onclose = function (event) {
    webSockets.coinMarketCap = null
    getWelupsPriceFromLBank(dispatch)
  }
  ws.onmessage = function (e) {
    const data = JSON.parse(e.data || '{}')
    const detail = data.d?.cr || {}
    if (data.id === 'price') {
      if (detail.id === 16665) {
        const result = {
          tick: {
            latest: detail.p?.toFixed(6),
            change: detail.p24h?.toFixed(2),
          },
        }
        dispatch(updateWelPrice(result))
      }
    } else if (data.action === 'ping') {
      setTimeout(() => {
        ws.send(
          JSON.stringify({
            pong: data.ping,
            action: 'pong',
          })
        )
      }, 1000)
    }
  }

  ws.onopen = function () {
    webSockets.coinMarketCap = ws

    ws.send(
      JSON.stringify({
        method: 'subscribe',
        id: 'price',
        data: {
          cryptoIds: [16665],
          index: 'price',
        },
      })
    )
  }
}

function getWelupsPriceFromXT(dispatch) {
  const ws = new WebSocket('wss://xtsocket.xt.com/websocket')
  ws.onerror = function (err) {
    ws.close()
    webSockets.xt = null
  }
  ws.onclose = function (event) {
    webSockets.xt = null
  }
  ws.onmessage = function (e) {
    const data = JSON.parse(e.data)
    if (data.code === 200) {
      const records = data.data?.records[0]
      if (records.length) {
        const result = {
          tick: {
            latest: records[2],
            change: records[3],
          },
        }
        dispatch(updateWelPrice(result))
      }
    } else if (data.ping) {
      setTimeout(() => {
        ws.send(
          JSON.stringify({
            pong: data.ping,
          })
        )
      }, 1000)
    }
  }
  ws.onopen = function () {
    webSockets.xt = ws
    ws.send(
      JSON.stringify({
        channel: 'ex_single_market',
        market: 'welups_usdt',
        event: 'addChannel',
      })
    )
  }
}

export function updateWelPrice(price) {
  return {
    type: HOME_WEL_RECENT_PRICE_UPDATE,
    payload: price?.tick || {},
  }
}

export const HOME_WEL_HISTORY_TRANSACTION_UPDATE = 'HOME_WEL_HISTORY_TRANSACTION_UPDATE'
export function fetchWelHistorical() {
  return (dispatch) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    /**
     * Hard code coinmarketcap API url. We don't need to change it anymore
     **/
    fetch(`${API_ADDR}/v2/cmc/cryptocurrency/detail/chart?range=1h`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(updateWelHistorical(result))
      })
      .catch((error) => console.log('error', error))
  }
}

export function updateWelHistorical(data) {
  return {
    type: HOME_WEL_HISTORY_TRANSACTION_UPDATE,
    payload: data,
  }
}
