import fetch from 'cross-fetch'
import { API_ADDR } from '../config/config'

export const GET_MARKET_INFO_REQUEST = 'GET_MARKET_INFO_REQUEST'
export const GET_MARKET_INFO_SUCCESS = 'GET_MARKET_INFO_SUCCESS'
export const GET_MARKET_INFO_FAIL = 'GET_MARKET_INFO_FAIL'

export function getMarketInfoRequest() {
  return {
    type: GET_MARKET_INFO_REQUEST,
  }
}
export function getMarketInfoSuccess(payload) {
  return {
    type: GET_MARKET_INFO_SUCCESS,
    payload,
  }
}
export function getMarketInfoFail() {
  return {
    type: GET_MARKET_INFO_FAIL,
  }
}

export function getMarketInfo(params) {
  return (dispatch) => {
    dispatch(getMarketInfoRequest())
    fetch(`${API_ADDR}/market-info?days=${params.days}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getMarketInfoSuccess(res.data))
      })
      .catch(() => dispatch(getMarketInfoFail()))
  }
}
