import {
  ADDRESS_LOAD,
  ADDRESS_DEFAULT,
  ADDRESS_TRANSACTION_DEFAULT,
  ADDRESS_TRANSACTION_LOAD,
  ADDRESS_ADDITION_DEFAULT,
  ADDRESS_ADDITION_LOAD,
} from '../actions/address'

var dataAddress = {
  address: '',
  totalbalance: '0',
  trxbalance: {
    available: '0',
    stake: '0',
  },
  transaction: {
    from: 0,
    to: 0,
  },
  tranfer: '0',
  created: '0',
  band_width_total: 0,
  energy_total: 0,
  energy_used: 0,
}

const defaultAddressState = dataAddress

export function addressReducer(state = [], action) {
  switch (action.type) {
    case ADDRESS_DEFAULT:
      state = defaultAddressState
      break
    case ADDRESS_LOAD:
      state = action.address
      break
  }
  return state
}

export function addressTransactionReducer(state = [], action) {
  switch (action.type) {
    case ADDRESS_TRANSACTION_LOAD:
      state = action.transacs
      break
    case ADDRESS_TRANSACTION_DEFAULT:
      state = defaultAddressTransactionState
      break
  }
  return state
}

var dataAddressTransaction = []

const defaultAddressTransactionState = dataAddressTransaction

export function addressAdditionInfoReducer(state = [], action) {
  switch (action.type) {
    case ADDRESS_ADDITION_LOAD:
      state = action.addition
      break
    case ADDRESS_ADDITION_DEFAULT:
      state = defaultAddressAdditionState
      break
  }
  return state
}

var dataAddressAddition = []

const defaultAddressAdditionState = dataAddressAddition
