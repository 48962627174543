import { API_ADDR } from '../config/config'
import fetch from 'cross-fetch'

export const ADDRESS_DEFAULT = 'ADDRESS_DEFAULT'
export const ADDRESS_LOAD = 'ADDRESS_LOAD'

export function defaultAddress() {
  return {
    type: ADDRESS_DEFAULT,
  }
}

export function loadAddress(address) {
  return {
    type: ADDRESS_LOAD,
    address: address,
  }
}

export function loadAddressApi(id) {
  return (dispatch) => {
    dispatch(defaultAddress())
    fetch(`${API_ADDR}/address/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadAddress(res.data.result))
      })
      .catch((err) => {
        fetch(`${API_ADDR}/address/findbyapi/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === 'success') {
              dispatch(loadAddress(res.data))
            } else {
              window.location.href = '/notfound'
            }
          })
          .catch((err) => {
            window.location.href = '/notfound'
          })
      })
  }
}

export const ADDRESS_TRANSACTION_DEFAULT = 'ADDRESS_TRANSACTION_DEFAULT'
export const ADDRESS_TRANSACTION_LOAD = 'ADDRESS_TRANSACTION_LOAD'

export function defaultAddressTransaction() {
  return {
    type: ADDRESS_TRANSACTION_DEFAULT,
  }
}

export function loadAddressTransaction(transacs) {
  return {
    type: ADDRESS_TRANSACTION_LOAD,
    transacs: transacs,
  }
}

export function loadAddressTransactionApi(id) {
  return (dispatch) => {
    dispatch(defaultAddressTransaction())
    fetch(`${API_ADDR}/address/transaction/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadAddressTransaction(res.data.result))
      })
      .catch((err) => {})
  }
}

export const ADDRESS_ADDITION_DEFAULT = 'ADDRESS_ADDITION_DEFAULT'
export const ADDRESS_ADDITION_LOAD = 'ADDRESS_ADDITION_LOAD'

export function defaultAddressAddition() {
  return {
    type: ADDRESS_ADDITION_DEFAULT,
  }
}

export function loadAddressAddition(addition) {
  return {
    type: ADDRESS_ADDITION_LOAD,
    addition: addition,
  }
}

export function loadAddressAdditionApi(id) {
  return (dispatch) => {
    dispatch(defaultAddressAddition())
    fetch(`${API_ADDR}/address/additioninfo/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadAddressAddition(res.data.result))
      })
      .catch((err) => {})
  }
}
