import React from 'react'
import { Input, AutoComplete, Tag, List, Select } from 'antd'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'

import {
  mainSearch,
  autoCompleteSearch,
  SEARCH_SUCCESS,
  filterSearch,
  filterAutoCompleteSearch,
} from '@/actions/home'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { loadHotTokens } from '@/actions/tokens'
import dropdownIcon from '@/assets/icon/arrow-dropdown.svg'
import checkIcon from '@/assets/icon/check.svg'
import './style.css'
import theme from '@/styles/theme'

const { Search } = Input
const { Option } = Select

const PALCEHOLDER = 'Search by Address/Txn ID/Token/Block'

const Wrapper = styled.div`
  margin: auto;
  margin-bottom: 18px;
  input {
    height: 30px;
    font-size: 12px;
  }
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 40px !important;
    border-right: 0;
    border-bottom-left-radius: 40px !important;
  }
  .ant-select {
    font-size: 12px;
  }
  .ant-select-selector {
    height: 42px !important;
    border-width: 2px !important;
    border-left: 0 !important;
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
    border-color: white !important;
    background-color: transparent !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
  .ant-select-arrow {
    right: 35px;
  }
  .ant-select-arrow .anticon {
    vertical-align: -webkit-baseline-middle;
  }
  .ant-input-affix-wrapper {
    border-width: 2px;
    border-color: white;
    background-color: transparent;
    input {
      background-color: transparent;
    }
    &-focused {
      border-color: ${({ theme }) => theme.colors_v2.white} !important;
    }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors_v2.white};
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`
const SearchWrapper = styled(Search)`
  .ant-input-search-button {
    display: none;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    width: 0 !important;
  }
`

const SearchWrapperMobile = styled(Search)`
  border: 2px solid ${({ theme }) => theme.colors_v2.white};
  border-radius: 20px;
  margin-bottom: 10px;
  input {
    background-color: transparent;
    border: none;
  }
`
const AutoCompleteWrapper = styled(AutoComplete)`
  width: 100%;
  margin-bottom: 10px;
  .ant-input-group-addon {
    background: transparent;
    button {
      border: none;
      color: ${({ theme }) => theme.colors_v2.white} !important;
    }
  }
`
const FlexDisplay = styled.div`
  display: flex;
`
const RightContent = styled.div`
  margin-left: 15px;
`
const SearchAutoCompleteTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const SearchAutoCompleteTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxmd};
`
const SubText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.gray_8};
`
const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSizesTitle.xxs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors_v2.primary_yellow};
  margin-bottom: 28px;
  margin-top: 95px;
  text-align: center;
  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    text-align: left;
    margin: 30px 0 15px 0;
  }
`
const SubHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.smd};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 40px;
  text-align: center;
  width: 70%;
  margin: auto;
  margin-bottom: 38px;
  @media (max-width: 576px) {
    width: 100%;
    text-align: left;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.xmd};
    margin-bottom: 20px;
  }
`

const SearchAutoCompleteList = styled(List)`
  border: none;
`

const SearchAutoCompleteTag = styled(Tag)`
  margin-left: 15px;
  border-radius: 5px;
  height: 17px;
  background: ${({ theme }) => theme.colors_v2.primary_yellow};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  line-height: 17px;
  border: none;
`

const NormalTag = styled(Tag)`
  margin-left: 15px;
  height: 17px;
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  line-height: 17px;
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colors_v2.primary_yellow};
  }
`
const TokenIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border-radius: 50%;
`

class SearchBox extends React.Component {
  state = {
    complete: false,
    showHistory: true,
    timer: null,
    filter: 0,
    searchKey: '',
    width: 0,
  }
  updateDimensions = () => {
    this.setState({ width: document.body.clientWidth })
  }
  componentDidMount() {
    this.props.loadHotTokens()
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  saveSearchHistory = (key) => {
    let searchHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]')
    const searchHistoryItem = {
      value: key,
    }
    if (searchHistory?.map((item) => item.value)?.includes(key)) return
    if (searchHistory?.length >= 10) {
      searchHistory.unshift(searchHistoryItem)
      localStorage.setItem('searchHistory', JSON.stringify(searchHistory.slice(0, 10)))
    } else {
      searchHistory.unshift(searchHistoryItem)
      localStorage.setItem('searchHistory', JSON.stringify(searchHistory))
    }
  }

  onSearch = (key) => {
    if (key !== '') {
      this.saveSearchHistory(key)
      this.setState({ showHistory: true })
      if (this.state.filter === 0) {
        this.props.mainSearch(key)
      } else {
        this.props.filterSearch(key, this.state.filter)
        // this.setState({ filter: 0 })
      }
      this.setState({ searchKey: '' })
    }
  }

  onChangeSearch = (e) => {
    const val = e?.target?.value
    clearTimeout(this.state.timer)
    if (val === '') {
      const newTimer = setTimeout(() => {}, 500)
      this.setState({ showHistory: true, timer: newTimer, searchKey: '' })
    } else {
      const newTimer = setTimeout(() => {
        if (this.state.filter === 0) {
          this.props.autoCompleteSearch(val, false)
        } else {
          this.props.filterAutoCompleteSearch(val, this.state.filter)
        }
      }, 500)
      this.setState({ showHistory: false, timer: newTimer, searchKey: val })
    }
  }

  changeFilter = (val) => {
    this.setState({ filter: val })
  }

  checkURL = (item) => {
    switch (this.props.autoCompleteType) {
      case 1:
        return `/transaction/${item?.hash}`
      case 2:
        return `/block/${item?.num}`
      case 6:
        return `/contract/${item?.addr}`
      case 7:
        return `/accounts/${item?.address}`
      case 9:
        const tokenType = item?.token_type === 'WRC10' ? '/token' : '/token20'
        return `${tokenType}/${item?._id}`
    }
  }

  checkVal = (item) => {
    switch (this.props.autoCompleteType) {
      case 1:
        return item?.hash
      case 2:
        return item?.num
      case 6:
        return item?.addr
      case 7:
        return item?.address
      case 9:
        return item?.token_name
      default:
        return item?.value
    }
  }

  renderItem = (item) => {
    const val = this.checkVal(item) || item?.value
    const searchKey = this.state.searchKey.toString()
    switch (this.props.autoCompleteType) {
      case 1:
      case 6:
        return (
          <SearchAutoCompleteTitle>
            <Highlighter
              searchWords={[`${searchKey}`]}
              autoEscape={true}
              textToHighlight={val?.toString()}
            />
          </SearchAutoCompleteTitle>
        )
      case 2:
        return (
          <SearchAutoCompleteTitle>
            <Highlighter
              searchWords={[`${searchKey}`]}
              autoEscape={true}
              textToHighlight={val?.toString()}
            />
            <SubText>Producer: {item.witness_address}</SubText>
          </SearchAutoCompleteTitle>
        )
      case 7:
        return (
          <SearchAutoCompleteTitle>
            <Highlighter
              searchWords={[`${searchKey}`]}
              autoEscape={true}
              textToHighlight={val?.toString()}
            />
            <SubText>Name: {item?.name}</SubText>
          </SearchAutoCompleteTitle>
        )
      case 9:
        return (
          <SearchAutoCompleteTitle>
            <TokenIcon
              src={item.token_icon ? item.token_icon : '/token-record-default.jpg'}
              onError={(e) => (e.target.src = '/token-record-default.jpg')}
            />
            <Highlighter
              searchWords={[`${searchKey}`]}
              autoEscape={true}
              textToHighlight={val?.toString()}
            />
            {item?.token_record_type && (
              <SearchAutoCompleteTag>{item?.token_record_type}</SearchAutoCompleteTag>
            )}
          </SearchAutoCompleteTitle>
        )
    }
  }

  onClickSearchItem = () => {
    this.saveSearchHistory(this.state.searchKey)
    this.setState({ showHistory: true })
  }

  mapTokenRecordType = (type) => {
    switch (type) {
      case 'WRC10':
        return 'token10'
      case 'WRC20':
        return 'token20'
      case 'WRC721':
        return 'token721'
      default:
        return 'token'
    }
  }

  renderHotTokens = (item, i) => {
    return {
      value: item?._id,
      label: (
        <SearchAutoCompleteList.Item key={i}>
          <FlexDisplay>
            {i < 3 && (
              <SearchAutoCompleteTag color={this.renderColorTag(i)}>{i + 1}</SearchAutoCompleteTag>
            )}
            {i >= 3 && <NormalTag>{i + 1}</NormalTag>}
            <RightContent>
              <SearchAutoCompleteTitleWrapper>
                <SearchAutoCompleteTitle>
                  <NavLink to={`/${this.mapTokenRecordType(item.token_record_type)}/${item._id}`}>
                    <TokenIcon
                      src={item.token_icon ? item.token_icon : '/token-record-default.jpg'}
                      onError={(e) => (e.target.src = '/token-record-default.jpg')}
                    />
                    {item?.token_name} ({item.token_abbreviation})
                  </NavLink>
                  {item?.token_record_type && (
                    <SearchAutoCompleteTag>{item?.token_record_type}</SearchAutoCompleteTag>
                  )}
                </SearchAutoCompleteTitle>
              </SearchAutoCompleteTitleWrapper>
            </RightContent>
          </FlexDisplay>
        </SearchAutoCompleteList.Item>
      ),
    }
  }

  renderColorTag = (i) => {
    switch (i) {
      case 0:
      case 1:
      case 2:
        return '#F7C11A'
      default:
        return 'transparent'
    }
  }

  renderContract = (item, i) => {
    return {
      value: i,
      label: (
        <NavLink to={() => this.checkURL(item)} onClick={() => this.onClickSearchItem()}>
          <FlexDisplay key={i}>
            <RightContent>
              <SearchAutoCompleteTitleWrapper>
                {this.renderItem(item)}
              </SearchAutoCompleteTitleWrapper>
            </RightContent>
          </FlexDisplay>
        </NavLink>
      ),
    }
  }

  renderTitle = () => {
    if (this.props.autoCompleteResult) {
      switch (this.props.autoCompleteType) {
        case 0:
        default:
          // this.props.loadHotTokens()
          return 'Not found'
        // return this.renderNotfound()
        case 1:
          return <span>Transactions</span>
        case 2:
          return <span>Blocks</span>
        case 6:
          return <span>Contracts</span>
        case 7:
          return <span>Accounts</span>
        case 9:
          return <span>Tokens</span>
      }
    }
  }

  options = () =>
    this.props.autoCompleteResult?.length > 0
      ? [
          {
            label: this.renderTitle(),
            options: this.props.autoCompleteResult?.map((item, i) => this.renderContract(item, i)),
          },
        ]
      : [
          {
            label: (
              <div>
                <ExclamationCircleOutlined style={{ color: theme.colors_v2.primary_yellow }} />
                &nbsp;Sorry! Search not found, recommended for you:
                <div>Hot Token</div>
              </div>
            ),
            options: this.props.hotTokens?.map((item, i) => this.renderHotTokens(item, i)),
          },
        ]

  historyOptions = () =>
    JSON.parse(localStorage.getItem('searchHistory')) && [
      {
        label: 'Search History',
        options: JSON.parse(localStorage.getItem('searchHistory'))?.map((item, i) => {
          return {
            value: i,
            label: <div onClick={() => this.onSearch(item?.value)}>{item?.value}</div>,
          }
        }),
      },
    ]

  onSelect = () => {
    this.setState({
      focus: false,
      showHistory: true,
      searchKey: '',
    })
  }

  renderContent = () => {
    const pathname = window.location.pathname

    if (this.state.width > 576) {
      return (
        <Wrapper>
          {pathname === '/home' && (
            <>
              <Header>Start and Build Your Crypto Portfolio Here</Header>
              <SubHeader>
                Only at Welups, you can build a good portfolio and learn
                <br /> best practices about cryptocurrency.
              </SubHeader>
            </>
          )}
          <Input.Group compact>
            <AutoComplete
              style={{ width: 'calc(100% - 150px)' }}
              options={this.state.showHistory ? this.historyOptions() : this.options()}
              value={this.state.searchKey}
              onFocus={() => this.setState({ focus: true })}
              onBlur={() => {
                this.setState({
                  focus: false,
                  searchKey: '',
                })
              }}
              dropdownClassName="search-dropdown"
              onSelect={this.onSelect}
              onChange={(val) => this.setState({ searchKey: val })}
            >
              <SearchWrapper
                onSearch={this.onSearch}
                placeholder={PALCEHOLDER}
                enterButton={null}
                prefix={<SearchOutlined />}
                onChange={this.onChangeSearch}
                // loading={SEARCH_REQUESTING === this.props.state}
              />
            </AutoComplete>
            <Select
              defaultValue={this.state.filter}
              style={{ width: '150px' }}
              dropdownClassName="search-filter-dropdown"
              onChange={this.changeFilter}
              menuItemSelectedIcon={<img src={checkIcon} />}
              suffixIcon={<img src={dropdownIcon} />}
            >
              <Option value={0}>All Filters</Option>
              <Option value={1}>Transaction</Option>
              <Option value={9}>Token</Option>
              <Option value={7}>Accounts</Option>
              <Option value={2}>Blocks</Option>
              <Option value={6}>Contract</Option>
            </Select>
          </Input.Group>
        </Wrapper>
      )
    }
    return (
      <>
        {pathname === '/home' && (
          <>
            <Header>Start and Build Your Crypto Portfolio Here</Header>
            <SubHeader>
              Only at Welups, you can build a good portfolio and learn best practices about
              cryptocurrency.
            </SubHeader>
          </>
        )}
        <AutoCompleteWrapper
          options={this.state.showHistory ? this.historyOptions() : this.options()}
          value={this.state.searchKey}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => {
            this.setState({
              focus: false,
              searchKey: '',
            })
          }}
          dropdownClassName="search-dropdown"
          onSelect={this.onSelect}
          onChange={(val) => this.setState({ searchKey: val })}
        >
          <SearchWrapperMobile
            placeholder={PALCEHOLDER}
            onChange={this.onChangeSearch}
            onSearch={this.onSearch}
          />
        </AutoCompleteWrapper>
      </>
    )
  }
  render() {
    const searchState = this.props.state
    const key = this.props.searchKey
    if (searchState === SEARCH_SUCCESS) {
      if (this.props.result?.length > 1) {
        return (
          <>
            <Redirect to={`/search/${key}`} />
            {this.renderContent()}
          </>
        )
      }
      switch (this.props.type) {
        case 0:
        default:
          return (
            <>
              <Redirect to={`/searchError`} />
              {this.renderContent()}
            </>
          )
        case 1:
          return (
            <>
              <Redirect to={`/transaction/${this.props.result?.[0]?.hash}`} />
              {this.renderContent()}
            </>
          )
        case 2:
          return (
            <>
              <Redirect to={`/block/${this.props.result?.[0]?.num}`} />
              {this.renderContent()}
            </>
          )
        case 6:
          return (
            <>
              <Redirect to={`/contract/${key}`} />
              {this.renderContent()}
            </>
          )
        case 7:
          return (
            <>
              <Redirect to={`/accounts/${this.props.result?.[0]?.address}`} />
              {this.renderContent()}
            </>
          )
        case 9:
          return (
            <>
              <Redirect to={`/search/${key}`} />
              {this.renderContent()}
            </>
          )
      }
    }

    return this.renderContent()
  }
}
const mapStateToProps = (state) => {
  return {
    state: state.search.state,
    result: state.search.result,
    autoCompleteResult: state.autoCompleteSearch.autoCompleteResult,
    autoCompleteType: state.autoCompleteSearch.type,
    type: state.search.type,
    searchKey: state.search.key,
    hotTokens: state.hotTokensReducer.hotTokens,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadHotTokens: () => {
      dispatch(loadHotTokens())
    },
    mainSearch: (key) => {
      dispatch(mainSearch(key))
    },
    filterSearch: (key, type) => {
      dispatch(filterSearch(key, type))
    },
    autoCompleteSearch: (key, isComplete) => {
      dispatch(autoCompleteSearch(key, isComplete))
    },
    filterAutoCompleteSearch: (key, type) => {
      dispatch(filterAutoCompleteSearch(key, type))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withRouter(SearchBox)
)
