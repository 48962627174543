import { FIND_TOKEN_HOLDERS_INIT, FIND_TOKEN_HOLDERS_UPDATE } from '../actions/findTokenHolders'

const initFindTokenHolders = []

export function findTokenHoldersReducer(state = initFindTokenHolders, action) {
  switch (action.type) {
    case FIND_TOKEN_HOLDERS_UPDATE:
      return action.payload
    case FIND_TOKEN_HOLDERS_INIT:
      return initFindTokenHolders
    default:
      return state
  }
}
