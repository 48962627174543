import Asset from '../api/asset'
import { notification } from 'antd'
import { API_ADDR } from '../config/config'
import { GLOBAL_SYMBOL } from '../constant'
import { GetPrivateKeyByAddress } from '../common/validate'
import { TransferAsset, TransferWRC10 } from '../common/transfer'

export const TRANSFER_NONE = 'TRANSFER_NONE'
export const TRANSFER_REQUESTING = 'TRANSFER_REQUESTING'
export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS'
export const TRANSFER_FAIL = 'TRANSFER_FAIL'
export function reset() {
  return {
    type: TRANSFER_NONE,
  }
}
export function request() {
  return {
    type: TRANSFER_REQUESTING,
  }
}
export function success(tranID) {
  return {
    type: TRANSFER_SUCCESS,
    payload: {
      tranID: tranID,
    },
  }
}
export function fail(message) {
  return {
    type: TRANSFER_FAIL,
    payload: message,
  }
}

export function transferAsset(privs, owner_address, to, amount, assetName) {
  var fromPrivKey = GetPrivateKeyByAddress(privs, owner_address)
  return async (dispatch) => {
    dispatch(request())
    var status = false
    var transID = ''
    var message = ''

    if (assetName == 'WEL') {
      var { statusTransfer, transactionID, messageTransfer } = await TransferAsset(
        fromPrivKey,
        owner_address,
        to,
        amount
      )
      status = statusTransfer
      transID = transactionID
      message = messageTransfer
    } else {
      var { statusTransfer, transactionID, messageTransfer } = await TransferWRC10(
        fromPrivKey,
        owner_address,
        to,
        amount,
        assetName
      )
      status = statusTransfer
      transID = transactionID
      message = messageTransfer
    }

    if (status) {
      if (transID) {
        dispatch(success(transID))
      } else {
        dispatch(fail(message))
        notification.error({
          message: message,
          description: `Transfer has failed`,
        })
      }
    } else {
      dispatch(fail(message))
      notification.error({
        message: message,
        description: `Transfer has failed`,
      })
    }
  }
}

export const TRANSFER_TOKENS_INIT = 'TRANSFER_TOKENS_INIT'
export const TRANSFER_TOKENS_UPDATE = 'TRANSFER_TOKENS_UPDATE'

export function initTokens() {
  return {
    type: TRANSFER_TOKENS_INIT,
  }
}
export function updateTokens(tokens) {
  return {
    type: TRANSFER_TOKENS_UPDATE,
    payload: tokens,
  }
}

export function loadTokens() {
  const defaultToken = { abbr: GLOBAL_SYMBOL, precision: 6, name: GLOBAL_SYMBOL, id: '' }
  return (dispatch) => {
    fetch(`${API_ADDR}/assets`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updatePageTokensTotal(res.data.length))
        const token10 = res.data.map((item) => {
          return { abbr: item.abbr, precision: item.precision, name: item.name, id: item.id }
        })
        const tokens = [defaultToken, ...token10]
        dispatch(updateTokens(tokens ? tokens : []))
      })
      .catch((err) => {
        dispatch(updateTokens([defaultToken]))
      })
  }
}

export const TRANSFER_PAGE_TOKENS_INIT = 'TRANSFER_PAGE_TOKENS_INIT'
export const TRANSFER_PAGE_TOKENS_UPDATE = 'TRANSFER_PAGE_TOKENS_UPDATE'
export const TRANSFER_PAGE_TOKENS_TOTAL_UPDATE = 'TRANSFER_PAGE_TOKENS_TOTAL_UPDATE'

export function initPageTokens() {
  return {
    type: TRANSFER_PAGE_TOKENS_INIT,
  }
}
export function updatePageTokens() {
  return {
    type: TRANSFER_PAGE_TOKENS_UPDATE,
  }
}

export function updatePageTokensTotal(total) {
  return {
    type: TRANSFER_PAGE_TOKENS_TOTAL_UPDATE,
    payload: total,
  }
}
