import { TOP_TOKEN_HOLDERS_INIT, TOP_TOKEN_HOLDERS_UPDATE } from '../actions/topTokenHolders'

const initTopTokenHolders = []

export function topTokenHoldersReducer(state = initTopTokenHolders, action) {
  switch (action.type) {
    case TOP_TOKEN_HOLDERS_UPDATE:
      return action.payload
    case TOP_TOKEN_HOLDERS_INIT:
      return initTopTokenHolders
    default:
      return state
  }
}
